import ButtonClientsModDesktop from '../components/ButtonClientsModDesktop';
import { CLIENT_FINAL_ID } from '../../../../helpers/rolesConstants';
import { NO_ORDERS_MSG } from './columnsTableMobile';

const columnsTableDesktop = [
  {
    name: 'N°',
    selector: (row) => row?.id,
    sortable: true,
    minWidth: '3%',
    sortField: 'id'
  },
  {
    name: 'CLIENTE',
    selector: (row) =>
      row?.client_type?.id === CLIENT_FINAL_ID
        ? `${row?.fullname}`
        : `${row?.company} | ${row?.fullname}`,
    sortable: true,
    minWidth: '30%',
    sortField: 'fullname'
  },
  {
    name: 'U$D',
    selector: (row) =>
      `${row?.total_dollar_balance 
        ? `US$ ${row?.total_dollar_balance}`
        : NO_ORDERS_MSG 
      }`,
    sortable: true,
    minWidth: '7%',
    sortField: 'total_dollar_balance'
  },
  {
    name: 'AR$',
    selector: (row) =>
      `${row?.total_peso_balance
        ? `$ ${row?.total_peso_balance}`
        : NO_ORDERS_MSG 
      }`,
    sortable: true,
    minWidth: '7%',
    sortField: 'total_peso_balance'
  },
  {
    name: 'TIPO',
    selector: (row) => row?.client_type?.client_type,
    sortable: true,
    minWidth: '10%',
    sortField: 'client_type'
  },
  {
    name: 'BONIFICACIONES',
    selector: (row) => `% ${row?.bonification}`,
    sortable: true,
    minWidth: '5%',
    sortField: 'bonification'
  },
  {
    name: '',
    cell: (row) => ButtonClientsModDesktop(row),
    sortable: false,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
