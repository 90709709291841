const calculateSectors = (editOrders) => {
  if (editOrders) {
    return editOrders?.sectors.map((sect, index) => {
      const itemList = sect.item_list;
      const its = [];
      itemList.forEach((it, indx) => {
        its.push({
          id: it.id,
          idItem: indx + 1,
          detail: it.item,
          quantity: it.quantity,
          price: it.price,
          currency: it.currency,
          width: it.width,
          height: it.height,
          depth: it.depth,
          itemId: it.item_id,
          observations: it.observations,
          bonification: it.bonification,
          orderSectorId: it.order_sector_id,
        });
      });
      return {
        id: sect.id,
        idSector: index + 1,
        items: its,
        orderId: sect.order_id,
        sector: sect.sector,
        sectorId: sect.sector_id,
        observations: sect.observations,
        facilities: sect.facilities,
      };
    });
  }
  return [];
};

export default calculateSectors;
