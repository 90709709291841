import PropTypes from 'prop-types';
import React from 'react';
import {
  Navigate, Route, Routes
} from 'react-router-dom';
import { ProtectedLayout } from '../../presentation/components/Layouts/ProtectedLayout';
import protectedPagesData from '../../presentation/pages/protectedPagesData';

export default function ProtectedRoute({
  isAuthenticated,
  isAllowed,
  redirectPath,
  permissions,
}) {
  const allowedRoutes = protectedPagesData.filter(({ category }) =>
    permissions.some(
      (permission) => category.includes(permission) || category.includes('all')
    )
  );

  if (isAuthenticated === 'no-authenticated' || !isAllowed) {
    return <Navigate to={ redirectPath } replace />;
  }

  // const pagesRoutes = allowedRoutes.map(({ path, title, element }) => (
  const pagesRoutes = allowedRoutes.map(({ path, title, element }) => (
    <Route key={ title } path={ `/${path}` } element={ element } />
  ));

  return (
    <Routes>
      <Route path="/" element={ <ProtectedLayout /> }>
        {pagesRoutes}
      </Route>
    </Routes>
  );
}

ProtectedRoute.defaultProps = {
  isAuthenticated: 'no-authenticated',
  isAllowed: false,
  redirectPath: '/login',
  permissions: [],
};

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.string,
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
};
