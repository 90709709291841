import React from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { editsUserComm } from '../../../data/redux/users/thunks';
import useForm from '../../hooks/useForm';
import useCommValidator from '../../hooks/useCommValidator';
import InputField from '../InputField/InputField';

function UserFormComm() {
  const { editUser, nomenclature, userComm, loading } = useSelector(
    (state) => state.user
  );

  const { formState, setFormState } = useForm({
    id: editUser ? editUser.id : '',
    commPercent: userComm.length === 0 ? 0 : userComm[0].commission,
  });

  const { errors, validateForm, onBlurField } = useCommValidator(formState, 3);

  const dispatch = useDispatch();

  const bonusableComm = nomenclature.filter((item) => item.bonusable);

  const commissionsForm = () => {
    const comms = [];

    // Lista de nuevas comisiones (Las que no estaban antes)
    const newComms = bonusableComm.filter(
      (obj) => !userComm.some((item) => item.nomenclature.id === obj.id)
    );

    newComms.map((comm) =>
      comms.push({
        id: null,
        nomenclature_id: comm.id,
        commission: Number(formState.commPercent),
      })
    );

    // Lista de comisiones actuales (Excepto las que se sacaron)
    const updateUserComm = userComm.filter((obj) =>
      bonusableComm.some((item) => obj.nomenclature.id === item.id)
    );

    updateUserComm.map((comm) =>
      comms.push({
        id: comm.id,
        nomenclature_id: comm.nomenclature.id,
        commission: Number(formState.commPercent),
      })
    );
    return comms;
  };

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };

    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const newForm = {
      commissions: commissionsForm(),
      user_id: editUser.id,
    };
    if (editUser) {
      dispatch(editsUserComm({
        id: formState.id, _body: newForm
      }));
    }
  };

  return !loading ? (
    <form className="form-base" action="">
      <div className="ctn-input">
        <InputField
          label="% COMISIÓN"
          name="commPercent"
          type="number"
          id="commPercent"
          value={ formState.commPercent }
          onBlur={ onBlurField } 
          onChange={ onInputChange }
          className={ errors.commPercent.error ? 'red-advertise' : '' }
          min={ 0 }
        />
      </div>
      <div className="container-client-form container-form">
        {errors.commPercent.dirty && errors.commPercent.error ? (
          <p className="error-validation">{errors.commPercent.message}</p>
        ) : null}
      </div>
      <div className='buttonSaveContainer'>
        <button className="btn-save" type="button" onClick={ handleFormSubmit }>
          <span className="material-icons-outlined">save</span>
        </button>
      </div>
    </form>
  ) : null;
}

export default UserFormComm;
