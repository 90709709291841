import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useElementHider from '../../../hooks/useElementHider';
import { selectUsers } from '../../../../data/redux/users/userSlice';
import { SUPER_ADMIN } from '../../../../helpers/rolesConstants';

function ButtonUserMod(row) {
  const [toggle, setToggle] = useState(false);
  const deleteModifyButtonsRef = useElementHider(setToggle);
  const { id, roles } = row;
  const { permissions } = useSelector((state) => state.auth);

  function mod() {
    setToggle(!toggle);
  }

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const handleChange = () => {
    dispatch(selectUsers(row));
    navigate('/user/form');
  };

  const idUser = JSON.parse(localStorage.getItem('persist:auth'))?.uid;

  const isUser = Number(idUser) === Number(id);

  const numUsuariosConAdmin = user?.filter((usuario) =>
    usuario?.roles?.some((rol) => rol.name === SUPER_ADMIN)
  )?.length;

  const isAdmin = roles?.some((rol) => rol.name === SUPER_ADMIN);

  return (
    <div ref={ deleteModifyButtonsRef }>
      {permissions.includes('client-delete') ||
        permissions.includes('client-update') ? (
          <div className="d-flex justify-content-center">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modalEditarRol"
              onClick={ mod }
            >
              <span>&#10247;</span>
            </button>
          </div>
        ) : null}
      {toggle === true ? (
        <div
          className={ `container-button-mod ${isUser || (isAdmin && Number(numUsuariosConAdmin) === 1)
            ? 'container-button-admin'
            : 'container-button-another-user'
          }` }
        >
          {permissions.includes('client-update') ? (
            <button type="button" onClick={ handleChange }>
              Modificar
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
export default ButtonUserMod;
