import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import UserFormData from './UserFormData';
import UserFormComm from './UserFormComm';
import {
  getNomenclatures,
  getUserComms,
} from '../../../data/redux/users/thunks';
import Spinner from '../Spinner/Spinner';

function UserForm() {
  const { editUser, loading, userComm } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (editUser) {
      dispatch(getUserComms(editUser.id));
      dispatch(getNomenclatures());
    }
  }, []);

  return !loading ? (
    <div className="container-main-form-user">
      <NavSec title="USUARIO" content={ userOptions } />
      <div className="container-form">
        <UserFormData />
        {userComm ? <UserFormComm /> : null}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default UserForm;
