import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../components/Images/Modal';

function LightboxGallery({ formState, setFormState, images, edit }) {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentindex, setCurrentindex] = useState(null);
  const [actModal, setActModal] = useState(false);

  function activeM() {
    setActModal(!actModal);
  }

  function getImg(img) {
    if (typeof img === 'string') {
      return `${process.env.REACT_APP_API_BACKEND}${img}`;
    }
    return URL.createObjectURL(img);
  }

  const handleClick = (item, index) => {
    setCurrentindex(index);
    setClickedImg(item.path ? item.path : item);
  };

  const { editOrders } = useSelector((state) => state.order);

  const handleRotationRight = () => {
    const totalLength = images.length;
    if (currentindex + 1 >= totalLength) {
      setCurrentindex(0);
      const newUrl = images[0].path ? images[0].path : images[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentindex + 1;
    const newUrl = images.filter((item) => images.indexOf(item) === newIndex);
    const newItem = newUrl[0].path ? newUrl[0].path : newUrl[0];
    setClickedImg(newItem);
    setCurrentindex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = images.length;
    if (currentindex - 1 >= totalLength) {
      setCurrentindex(0);
      const newUrl = images[0].path ? images[0].path : images[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentindex - 1;
    const newUrl = images.filter((item) => images.indexOf(item) === newIndex);
    const newItem = newUrl[0].path ? newUrl[0].path : newUrl[0];
    setClickedImg(newItem);
    setCurrentindex(newIndex);
  };

  const handleImageDelete = (index) => {
    const updatedFiles = formState.images.filter((file, i) => i !== index);
    setFormState({
      ...formState, images: updatedFiles
    });
  };

  return (
    <>
      <div className="img-config--container">
        <div className="container-mini-img-scroll">
          {Array.from(images).map((item, index) => (
            <div className="container-slider-img" key={ item.id }>
              <button
                type="button"
                className="btn-img"
                onClick={ () => handleClick(item, index) }
              >
                <img
                  src={
                    editOrders && item.path
                      ? `${process.env.REACT_APP_API_BACKEND}${item.path}`
                      : URL.createObjectURL(item)
                  }
                  alt={ item.name }
                />
              </button>
              {edit && (
                <button
                  type="button"
                  className="delete-button"
                  onClick={ () => handleImageDelete(index) }
                >
                  <span className="material-icons-outlined">delete</span>
                </button>
              )}
            </div>
          ))}
          {actModal && (
            <Modal
              clickedImg={ clickedImg }
              handleRotationRight={ handleRotationRight }
              handelRotationLeft={ handelRotationLeft }
              setActModal={ setActModal }
            />
          )}
        </div>
      </div>
      <div className="visualizador-img-select">
        {clickedImg ? null : (
          <h2>
            ¡Para previsualizar una imagen, por favor haga click en alguna de
            ellas!
          </h2>
        )}
        <img src={ clickedImg ? getImg(clickedImg) : null } alt="" />
        {clickedImg ? (
          <button onClick={ activeM } type="button" className="btn-expand">
            <span className="material-icons-round">open_in_full</span>
          </button>
        ) : null}
      </div>
    </>
  );
}

LightboxGallery.propTypes = {
  formState: PropTypes.objectOf.isRequired,
  setFormState: PropTypes.func.isRequired,
  images: PropTypes.arrayOf,
  edit: PropTypes.bool,
};

LightboxGallery.defaultProps = {
  images: [],
  edit: true,
};

export default LightboxGallery;
