const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  number: /^[0-9]+$/,
  cuilCuit: /^[0-9]{2}-[0-9]{8}-[0-9]{1}$/,
  dni: /^[0-9]{8}$/,
};

export class Validators {
  static email(value, message) {
    if (!value) return false;

    const result = regex.email.test(value);

    if (!result) return {
      error: true, message
    };

    return false;
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length)
      return {
        error: true, message
      };

    return false;
  }

  static password(value, message) {
    if (!value) return false;

    const result = regex.password.test(value);

    if (!result) return {
      error: true, message
    };

    return false;
  }

  static number(value, message) {
    if (!value) return false;

    const result = regex.number.test(value);

    if (!result) return {
      error: true, message
    };

    return false;
  }

  static cuilCuit(value, message) {
    if (!value) return false;

    const result = regex.cuilCuit.test(value);

    if (!result) return {
      error: true, message
    };

    return false;
  }
}

export const validateInput = (validators, value) => {
  if (validators && validators.length) {
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const [index, validator] of validators.entries()) {
      const error = validators[index].check(value, validators[index].message);
      if (error) return error;
    }
    // eslint-disable-next-line consistent-return
    // validators.forEach((validator) => {
    //   const error = validator.check(value, validator.message)
    //   if (error) return error
    // })
    // for (let i = 0; i < validators.length; i += 1) {
    //   const error = validators[i].check(value, validators[i].message)
    //   if (error) return error
    // }
  }
  return false;
};
