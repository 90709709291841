import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  selectOrder,
} from '../../../../data/redux/Orders/orderSlice';
import calcularCantidadItemsSector from '../utils/calcularCantidadItemsSector';

function QuotesDetailsTableMobile({ data }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectOrder(data));
  };

  return (
    <div className="container-detail-orders">
      <div className="container-detail-main-orders">

        <div className="container-colum-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">CUIT/CIUL/DNI:</h3>
            <p className="p-Details">{ data?.client?.cuit }</p>
            <h3 className="p-title-detail">Telefono:</h3>
            <p className="p-Details">{ data?.client?.phone }</p>
            <h3 className="p-title-detail">Dirección:</h3>
            <p className="p-Details">{ data?.client?.address }</p>
          </div>
        </div>

        <div className="container-colum-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Sectores - Artículos</h3>
            { data?.sectors?.map((e) => (
              <p>
                { e.sector.sector } { `- ${calcularCantidadItemsSector(e)} Art` }
              </p>
            )) }
          </div>
        </div>

        <div className="container-colum-orders">
          <Link to="final" onClick={ handleClick }>
            <span className="material-icons-outlined">article</span>
          </Link>
        </div>

      </div>

      <div className="container-detail-main-orders">

        <div className="container-colum-orders">
          <div className="detail-data-orders place-orders">
            <h3 className="p-title-detail">Lugar:</h3>
            <p className="p-Details saldos">{ data?.place }</p>
          </div>
        </div>

        <div className="container-colum-orders observation-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Observaciones:</h3>
            <p className="p-Details saldos">
              { data?.observation ? data?.observation : 'Sin observaciones' }
            </p>
          </div>
        </div>

      </div>

      <div className="line-red">.</div>
    </div>
  );
}

QuotesDetailsTableMobile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    effective_date: PropTypes.string,
    special_comm: PropTypes.bool,
    is_open: PropTypes.bool,
    version: PropTypes.number,
    peso_balance: PropTypes.number,
    dollar_balance: PropTypes.number,
    peso_bonification: PropTypes.number,
    dollar_bonification: PropTypes.number,
    peso_comm: PropTypes.number,
    dollar_comm: PropTypes.number,
    client_name: PropTypes.string,
    observation: PropTypes.string,
    place: PropTypes.string,

    user: PropTypes.shape({
      id: PropTypes.number,
      fullname: PropTypes.string,
      CUIT: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      enabled: PropTypes.number,
    }),

    client: PropTypes.shape({
      id: PropTypes.number,
      fullname: PropTypes.string,
      cuit: PropTypes.number,
      email: PropTypes.string,
      address: PropTypes.string,
      bonification: PropTypes.number,
      observations: PropTypes.string,
      enabled: PropTypes.bool,
      client_type: PropTypes.shape({
        id: PropTypes.number,
        client_type: PropTypes.string,
      }),
    }),

    order_status: PropTypes.shape({
      id: PropTypes.number,
      order_status: PropTypes.string,
    }),

    payment_status: PropTypes.shape({
      id: PropTypes.number,
      payment_status: PropTypes.string,
    }),

    sectors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        observations: PropTypes.string,
        order_id: PropTypes.number,
        sector_id: PropTypes.number,
        item_list: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            quantity: PropTypes.number,
            width: PropTypes.number,
            heigth: PropTypes.number,
            depth: PropTypes.number,
            observations: PropTypes.string,
            price: PropTypes.number,
            currency: PropTypes.string,
            bonification: PropTypes.number,
            order_sector_id: PropTypes.number,
            item_id: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
};

export default QuotesDetailsTableMobile;
