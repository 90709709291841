import ButtonArticleMod from '../components/ButtonItemsMod';

import { caseInsensitiveSort, numberSort } from './columnsTableDesktop';

const columnsTableMobile = [
  {
    name: 'ARTÍCULOS',
    selector: (row) => row?.item,
    sortable: true,
    minWidth: '42%',
    sortFunction: caseInsensitiveSort
  },
  {
    name: 'PRECIO',
    selector: (row) => `${row?.currency?.currency} ${row?.price}`,
    sortable: true,
    minWidth: '42%',
    sortFunction: numberSort
  },
  {
    name: '',
    cell: (row) => ButtonArticleMod(row),
    sortable: true,
    button: true,
    minWidth: '10%',
  },
];
export default columnsTableMobile;
