import React from 'react';
import PropTypes from 'prop-types';
import { rolesOptions } from '../../../../helpers/dataOptions';

function DetailsTableMobile({ data }) {
  const roleOption = rolesOptions.find(
    (option) => option.name === data?.roles[0]?.name
  );
  const displayName = roleOption ? roleOption.displayName : '';

  return (
    <div className="container-detail-main">
      <div className="detail-data">
        <p className="p-title-detail">CUIT:</p>
        <p className="p-Details">{data?.CUIT}</p>
        <p className="p-title-detail">EMAIL:</p>
        <p className="p-Details">{data?.email}</p>
        <p className="p-title-detail">DIRECCIÓN:</p>
        <p className="p-Details">{data?.address}</p>
        <p className="p-title-detail">TEL:</p>
        <p className="p-Details">{data?.phone}</p>
      </div>
      <div className="container-btn-detail">
        <div className="btn-detail">
          <p className="p-title-detail">COMISIÓN:</p>
          <p className="p-Details">
            {data?.commissions?.length
              ? `${data?.commissions[0]?.commission}%`
              : '0%'}
          </p>
        </div>
        <div className="btn-detail">
          <p className="p-title-detail">ROL:</p>
          <p className="p-Details">{displayName}</p>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

DetailsTableMobile.propTypes = {
  data: PropTypes.shape({
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    commissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        commission: PropTypes.number.isRequired,
      })
    ),
    fullName: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    CUIT: PropTypes.string,
    enabled: PropTypes.number,
  }).isRequired,
};

export default DetailsTableMobile;
