import axios from 'axios';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useState,
  useEffect,
  useRef,
} from 'react';
import DataTable from 'react-data-table-component';
import CustomPagination from './CustomPagination';
import {
  setCurrentPageOrder,
  setOrderColumn,
  setLastPage,
  changeLoading,
  setSearchValue,
} from '../../../data/redux/Orders/orderSlice';
import config from '../../../data/redux/config/basequery';
import Spinner from '../Spinner/Spinner';

function Table({
  columnsTable,
  messageNoData,
  selectRows,
  filterEnabled,
  className,
  conditionalStyles,
  filteredData,
  conceptsFilter,
  section,
  expandedRow,
  detailsTable,
  onSelectedRowsChange,
  pagination,
  sortServer,
  searchByDb = {
  },
}) {
  const dispatch = useDispatch();
  const controllerRef = useRef(null);

  const { permissions } = useSelector((state) => state.auth);
  const { lastPageOrder, currentPageOrder, orderColumn, loading } = useSelector((state) => state.order);
  
  const [searchInputValue, setSearchInputValue] = useState('');
  const [currentRow, setCurrentRow] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  const calculatePageCount = (data) => {
    return Math.ceil(data.length / 10);
  };

  useEffect(() => {
    if (!filteredItems.length) {
      setFilteredItems(filteredData);
    }

    if (!searchByDb.isUsed) {
      dispatch( setLastPage( calculatePageCount(filteredData) ) ); 
    }
  }, [filteredData]);

  const runFilters = (val) => {
    let updatedData = [];
    let result = [];
    if (val.length) {
      const filterByConcepts = (concepts, data, res) => {
        if (concepts.length === 0) {
          return res;
        }

        const concept = concepts[0];
        const filterData = data?.filter((item) => {
          let itemConcept;
          if (concept.includes('.')) {
            const [outerProperty, innerProperty, lastProperty] =
              concept.split('.');
            if (!lastProperty) {
              itemConcept = item[outerProperty][innerProperty];
            } else if (!innerProperty) {
              itemConcept = item[outerProperty];
            } else {
              itemConcept = item[outerProperty][innerProperty][lastProperty];
            }
          } else {
            itemConcept = item[concept];
          }
          const filter =
            itemConcept &&
            itemConcept
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase().trim());
          return filter;
        });

        const filteredResult = res.concat(filterData);

        return filterByConcepts(concepts.slice(1), filteredData, filteredResult);
      };

      result = filterByConcepts(conceptsFilter, filteredData, result);

      updatedData = result.reduce((acc, data) => {
        if (!acc.includes(data)) {
          acc.push(data);
        }
        return acc;
      }, []);
      updatedData.sort((a, b) => {
        const valueA = a[conceptsFilter[0]];
        const valueB = b[conceptsFilter[0]];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB);
        }
        return valueA - valueB;
      });
      dispatch( setLastPage(calculatePageCount(updatedData)) );
      dispatch(setCurrentPageOrder(0));
      setFilteredItems(updatedData);
    }
  };
  
  const handleSearchByDB = async (value) => {
    try {
      dispatch(changeLoading(true));
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const signal = controllerRef?.current?.signal;

      const baseUrl = `${process.env.REACT_APP_API_BACKEND}api`;
      const params = {
        page: 1,
        per_page: 10,
        column_name: orderColumn.column,
        column_order: orderColumn.order,
        search: value,
        ...searchByDb?.params,
      };
      const { data } = await axios.get(`${baseUrl}${searchByDb?.endpoint}`, {
        ...config(),
        params,
        signal,
      });

      dispatch(setSearchValue(value));
      dispatch(setCurrentPageOrder(0));
      if (searchByDb.endpoint === '/orders') {
        dispatch(setLastPage(data?.orders?.last_page));
        setFilteredItems(data?.orders?.data);
      } else {
        dispatch(setLastPage(data?.clients?.last_page));
        setFilteredItems(data?.clients?.data);
      }

    } catch (error) {
      if (error?.response?.code !== 'ERR_CANCELED') {
        controllerRef.current = null;
      }
    } finally {
      dispatch(changeLoading(false));
    }
  };

  const handlePagination = (page) => {
    dispatch(setCurrentPageOrder(page.selected));
  };
  
  const handleSearch = async (value) => {
    if (currentPageOrder > lastPageOrder) {
      dispatch(setCurrentPageOrder(lastPageOrder));
    } else if (searchByDb?.isUsed){ 
      await handleSearchByDB(value); 
    } else if (value) {
      runFilters(value); 
    } else {
      setFilteredItems(filteredData);
      dispatch(setLastPage(calculatePageCount(filteredData)));
    }
  };

  const passthroughPagination = () => (
    <CustomPagination
      currentPage={ currentPageOrder }
      handlePagination={ handlePagination }
      pageCount={ lastPageOrder }
    />
  );

  const { id } = useParams();

  const handleSort = (selectedColumn, sortDirection) => {
    dispatch(
      setOrderColumn({
        column: selectedColumn.sortField, order: sortDirection, id: selectedColumn.id
      })
    );
  };

  const handlePermissions = () => {
    if ( permissions?.includes(`${section}-read`) ) {
      return (
        <DataTable
          selectableRows={ selectRows }
          columns={ columnsTable }
          data={ filteredItems }
          customStyles={{
            headCells: {
              style: {
                backgroundColor: 'none'
              },
            },
          }}
          noDataComponent={ messageNoData }
          className={ `react-dataTable ${className} my-2` }
          expandableRows={ expandedRow }
          expandOnRowClicked
          expandableRowExpanded={ (row) => row === currentRow }
          onRowClicked={ (row) => setCurrentRow(row) }
          onRowExpandToggled={ (bool, row) => setCurrentRow(row) }
          expandableRowsComponent={ detailsTable }
          conditionalRowStyles={ conditionalStyles }
          onSelectedRowsChange={ onSelectedRowsChange }
          pagination={ pagination }
          paginationPerPage={ 10 }
          paginationDefaultPage={ currentPageOrder + 1 }
          paginationComponent={ passthroughPagination }
          defaultSortFieldId={ orderColumn.id }
          defaultSortAsc={ orderColumn.order === 'asc' }
          onSort={ handleSort }
          sortServer={ sortServer }
        />
      );
    } 
    return <div>User does not have the credentials for this action.</div>;
  };

  return (
    <div className="datatable--responsive container">
      {filterEnabled ? (
        <div className="container-filter">
          {permissions?.includes(`${section}-create`) ? (
            <button className="btn-filter" type="button">
              <Link to={ id ? '/orders/form' : 'form' }>
                <span className="material-icons-outlined">playlist_add</span>
              </Link>
            </button>
          ) : null}
          <input
            className={ `inp-filter ${ loading && 'inp-filter-disabled' }` }
            value={ searchInputValue }
            type="search"
            disabled={ loading }
            onChange={ (e) => setSearchInputValue(e.target.value) }
            onKeyDown={ (e) => { 
              if (e.key === 'Enter') {
                handleSearch(searchInputValue);
              } 
            } }
          />
          <button className="btn-filter"
            type="submit"
            onClick={ () => { 
              setSearchInputValue('');
              dispatch(setSearchValue(''));
              dispatch(setCurrentPageOrder(0));
              handleSearch(''); 
            } } 
          >
            <span className="material-icons-outlined">backspace</span>
          </button>
        </div>
      ) : (
        ''
      )}
      { 
        loading ? <Spinner /> : (handlePermissions())
      }
    </div>
  );
}

Table.propTypes = {
  filterEnabled: PropTypes.bool,
  className: PropTypes.string,
  conditionalStyles: PropTypes.arrayOf(PropTypes.shape),
  columnsTable: PropTypes.arrayOf(PropTypes.shape),
  filteredData: PropTypes.arrayOf(PropTypes.shape),
  filterOrders: PropTypes.string,
  conceptsFilter: PropTypes.arrayOf(PropTypes.shape),
  section: PropTypes.string,
  messageNoData: PropTypes.node,
  selectRows: PropTypes.bool,
  expandedRow: PropTypes.bool,
  detailsTable: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  pagination: PropTypes.bool,
  lastPageByFilteredData: PropTypes.bool,
};

Table.defaultProps = {
  filterEnabled: false,
  className: '',
  columnsTable: [],
  conditionalStyles: [],
  filteredData: [],
  filterOrders: '',
  conceptsFilter: [],
  section: '',
  messageNoData: '',
  selectRows: true,
  expandedRow: true,
  detailsTable: () => { },
  onSelectedRowsChange: () => { },
  pagination: true,
  lastPageByFilteredData: false
};

export default Table;
