import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { setLastPage } from '../Orders/orderSlice';

import config from '../config/basequery';

// eslint-disable-next-line import/no-cycle
import {
  addClient,
  deleteClient,
  editAuditComm,
  editClient,
  editClientComm,
  onLoadingClient,
  selectAuditComms,
  selectClientComms,
  selectNomenclatures,
} from './clientSlice';

const getClient = createAsyncThunk('client/getClients', async (_, { getState, dispatch }) => {
  try {
    const state = getState();
    const { currentPageOrder, orderColumn, searchValue } = state.order;
    const params = { 
      page: currentPageOrder+1,
      per_page: 10,
      column_name: orderColumn.column || 'id',
      column_order: orderColumn.order || 'desc',
      search: searchValue
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/clients`,
      {
        params,
        ...config()
      }
    );
    dispatch(setLastPage(data.clients.last_page));
    return data?.clients?.data;
  } catch (error) {
    toast(error.message);
    return [];
  }
});

export default getClient;

export const createClient = (form, onFinish = () => {}) => async (dispatch) => {
  dispatch(onLoadingClient());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/clients`,
      form,
      config()
    );
    dispatch(addClient(data.data));
    toast.success(data.message);
    return;
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    onFinish();
  }
};

export const editsClient =
  ({ id, _body }, onFinish = () => {}) =>
    async (dispatch) => {
      dispatch(onLoadingClient());
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}api/clients/${id}`,
          _body,
          config()
        );
        dispatch(editClient(data.data));
        toast.success(data.message);
        return;
      } catch (error) {
        const data = error.response.data.message;
        toast.error(data);
      } finally {
        onFinish();
      }
    };

export const getNomenclatures = () => async (dispatch) => {
  dispatch(onLoadingClient());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/nomenclatures`,
      config()
    );
    dispatch(selectNomenclatures(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getClientComms = (id) => async (dispatch) => {
  dispatch(onLoadingClient());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/clientComm/${id}`,
      config()
    );
    dispatch(selectClientComms(data.data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const editsClientComm =
  ({ id, _body }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}api/clientComm/${id}`,
          _body,
          config()
        );
        dispatch(editClientComm(data.data));
        toast.success(data.message);
        return;
      } catch (data) {
        toast.error(data.message);
      }
    };

export const getAuditsComms = () => async (dispatch) => {
  dispatch(onLoadingClient());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/auditComm`,
      config()
    );
    dispatch(selectAuditComms(data.data));
    return;
  } catch (data) {
    // toast.error(data.message)
    dispatch(selectAuditComms([]));
    // toast.error('No hay comisiones pendientes')
  }
};

export const getAuditComms = (id) => async (dispatch) => {
  dispatch(onLoadingClient());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/auditComm/${id}`,
      config()
    );
    dispatch(selectAuditComms(data.data));
    return;
  } catch (data) {
    toast.error('data.message');
  }
};

export const editsAuditComm =
  ({ id, _body }) =>
    async (dispatch) => {
      if (!_body.reset) {
        dispatch(onLoadingClient());
      }
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}api/auditComm/${id}`,
          _body,
          config()
        );
        dispatch(selectAuditComms(data.data));
        toast.success(data.message);
        return;
      } catch (data) {
        toast.error(data.message);
      }
    };

export const createAuditComm =
  ({ _body }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_BACKEND}api/auditComm`,
          _body,
          config()
        );
        dispatch(editAuditComm(data.data));
        toast.success(data.message);
        return;
      } catch (data) {
        toast.error(data.message);
      }
    };

export const deletesClient = (id) => async (dispatch) => {
  // dispatch(onLoadingClient())
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BACKEND}api/clients/${id}`,
      config()
    );
    dispatch(deleteClient(id));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};
