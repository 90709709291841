import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ordersOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import OrdersFinalCard from './OrdersFinalCard';
import { getSectors } from '../../../data/redux/Orders/thunks';
import Spinner from '../Spinner/Spinner';
import LightboxGallery from '../../utils/LightboxGallery';
import {
  selectVersion,
  selectCurrentVersion,
} from '../../../data/redux/Orders/orderSlice';

function OrdersFinal() {
  const navigate = useNavigate();
  const { editOrders, currentOrder, loading, sectors } = useSelector(
    (state) => state.order
  );
  const { roles } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const ordersVersions = editOrders?.order_versions
    ? [...editOrders.order_versions]
    : null;

  const handleClose = () => {
    navigate(-1);
  };

  const handleClick = (index) => {
    dispatch(selectVersion(ordersVersions[index]));
    window.scrollTo(0, 0);
  };

  const handleVersionCurrent = () => {
    dispatch(selectCurrentVersion(currentOrder));
    window.scrollTo(0, 0);
  };

  const handleFormatNumbers = (value) => {
    return Math.round(value * 100) / 100;
  };

  useEffect(() => {
    if (sectors?.length === 0) dispatch(getSectors());
  }, []);

  return !loading ? (
    <div className="container-order-main-final">
      <NavSec title="PEDIDOS" content={ ordersOptions } />
      <div className="orders-final">
        <div className="container-orders-final">
          <div className="container-btn-orders">
            {editOrders?.order_versions ? null : (
              <Link to="/orders/final" onClick={ handleVersionCurrent }>
                <button className="btn-version" type="button">
                  Ir a versión vigente
                </button>
              </Link>
            )}
            <button className="btn-close" type="button" onClick={ handleClose }>
              <span className="material-icons-outlined">close</span>
            </button>
          </div>

          <div className="container-red-orders">
            <div className="container-user-orders">
              <h3>USUARIO</h3>
              <p>{editOrders?.user.fullName}</p>
            </div>
            <div className="container-data-orders">
              <h3>CLIENTE:</h3>
              <p>{
                editOrders?.client.company ?   
                  `${editOrders?.client.fullname} | ${editOrders?.client.company}`
                  : `${editOrders?.client.fullname}`
              }</p>
              <h3>N° DE PRESUPUESTO</h3>
              <p>
                {editOrders?.origin_order}.{editOrders?.version}
              </p>
              <h3>PEDIDO</h3>
              <p>{editOrders?.is_open ? 'Abierto' : 'Cerrado'}</p>
            </div>
            <div className="container-data-orders">
              <h3>FECHA INICIAL</h3>
              <p>{editOrders?.start_date.slice(0, 10)}</p>
              <h3>FECHA DE VIGENCIA</h3>
              <p>{editOrders?.effective_date.slice(0, 10)}</p>
              <h3>ESTADO DEL PEDIDO</h3>
              <p>{editOrders?.order_status.order_status}</p>
            </div>
          </div>
          {editOrders?.sectors.map((sect) => (
            <OrdersFinalCard key={ sect.id } sector={ sect } />
          ))}
          <div className="container-gallery-form">
            {editOrders?.images?.length > 0 && (
              <LightboxGallery edit={ false } images={ editOrders?.images } />
            )}
          </div>
          {ordersVersions ? (
            <div className="container-mod-orders">
              <h3>MODIFICACIONES</h3>
              <div className="card-mod-orders">
                {ordersVersions ? (
                  ordersVersions.map((data, index) =>
                    data.updated_at ? (
                      <div className="container-versions">
                        {' '}
                        Versión {data.origin_order}.{data.version} -{' '}
                        {new Date(data.updated_at).toLocaleString('es-AR')}
                        <div>
                          <Link
                            to="/orders/final"
                            onClick={ () => handleClick(index) }
                          >
                            <button
                              className="btn-detalle-version"
                              type="button"
                            >
                              Ver detalles
                            </button>
                          </Link>
                        </div>
                      </div>
                    ) : null
                  )
                ) : (
                  <p> No realizaron modificaciones </p>
                )}
              </div>
            </div>
          ) : null}

          {roles[0]?.name.includes('D-Operations') ? null : (
            <>
              <div className="container-pay-orders">
                <h3>PAGOS</h3>
                {editOrders?.payments.length ? (
                  <div className="card-pay-orders">
                    {editOrders?.payments.map((payment) => (
                      <div className="order-final-payment">
                        <div
                          className="order-final-payment-data"
                          key={ payment.id }
                        >
                          <p>{payment.payment_term.payment_term}</p>
                          <p>{payment.payment_date.slice(0, 10)}</p>
                          <p>
                            {payment.currency.currency} {payment.payment_amount}
                          </p>
                          <p
                            className={
                              payment.observations ? 'active' : 'hidden'
                            }
                          >
                            {payment.observations}
                          </p>
                        </div>
                        <div className="line-white">.</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="card-pay-orders">
                    <div className="data-pay-orders">
                      <p>No se realizaron pagos</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="container-total-orders">
                <h3>TOTAL</h3>
                <div className="container-data-orders">
                  <div className="data-total-orders">
                    <h3>SALDO TOTAL</h3>
                    <h3>BONIFICACIÓN</h3>
                    <h3>TOTAL A PAGAR</h3>
                    <h3>COMISIONES</h3>
                  </div>
                  <div className="data-total-orders">
                    <p>{`$ ${handleFormatNumbers( ( editOrders?.peso_balance ?? 0 ) + (editOrders?.peso_bonification ?? 0) )}`}</p>
                    <p>{`$ ${handleFormatNumbers( editOrders?.peso_bonification )}`}</p>
                    <p>{`$ ${handleFormatNumbers( editOrders?.peso_balance )}`}</p>
                    <p>{`$ ${handleFormatNumbers( editOrders?.peso_commission )}`}</p>
                  </div>
                  <div className="data-total-orders">
                    <p>{`US$ ${handleFormatNumbers( ( editOrders?.dollar_balance ?? 0 ) + (editOrders?.dollar_bonification ?? 0) )}`}</p>
                    <p>{`US$ ${handleFormatNumbers( editOrders?.dollar_bonification )}`}</p>
                    <p>{`US$ ${handleFormatNumbers( editOrders?.dollar_balance )}`}</p>
                    <p>{`US$ ${handleFormatNumbers( editOrders?.dollar_commission )}`}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default OrdersFinal;
