import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table/Table';
import DetailsTableDesktop from './components/DetailsTableDesktop';
import columnsTableDesktop from './Tables/columnsTableDesktop';
import { setLoading } from '../../../data/redux/Units/measurementUnitSlice';

function Units() {
  const dispatch = useDispatch();
  const { measurementunit, loading } = useSelector(
    (state) => state.measurementunit
  );
  useEffect(() => {
    dispatch(setLoading(false));
  }, [measurementunit]);

  return (
    !loading && (
      <Table
        className="marmoleria--datatable table-units"
        selectRows={ false }
        columnsTable={ columnsTableDesktop }
        filteredData={ measurementunit }
        detailsTable={ DetailsTableDesktop }
        filterEnabled
        conceptsFilter={ [
          'measurement_unit',
          'nomenclature',
          'description',
          'unit_type.unit_type',
        ] }
        section="unit"
        messageNoData={
          <h1 className="no-data-message">NO SE CREARON UNIDADES</h1>
        }
        lastPageByFilteredData
      />
    )
  );
}

export default Units;
