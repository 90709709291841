import { useState } from 'react';
import {
  selectValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useOrdersValidator = (form) => {
  const [errors, setErrors] = useState({
    clientId: {
      dirty: true,
      error: false,
      message: '',
    },
    place: {
      dirty: true,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { clientId, place } = form;

    if (nextErrors.clientId.dirty && (field ? field === 'clientId' : true)) {
      const clientIdMessage = selectValidator(clientId);
      nextErrors.clientId.error = !!clientIdMessage;
      nextErrors.clientId.message = clientIdMessage;
      if (clientIdMessage) isValid = false;
    }
    if (nextErrors.place.dirty && (field ? field === 'place' : true)) {
      const placeMessage = textValidator(place);
      nextErrors.place.error = !!placeMessage;
      nextErrors.place.message = placeMessage;
      if (placeMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({
      form, field, errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField,
  };
};

export default useOrdersValidator;
