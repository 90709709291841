import React from 'react';
import PropTypes from 'prop-types';

function DetailsTableDesktop({ data }) {
  return (
    <div className="container-units">
      <div className="container-detail-units">
        <div className="detail-data detail-units">
          <div className="container-unidad-type">
            <h3 className="h-detail-units">TIPO DE UNIDAD:</h3>
            <p className="units-d">{data?.unit_type?.unit_type}</p>
          </div>
          <div className="container-unidad-description">
            <h3 className="h-detail-units">DESCRIPCIÓN:</h3>
            <p className="units-d">
              {data?.description ? data?.description : 'No tiene descripción'}
            </p>
          </div>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

DetailsTableDesktop.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    measurement_unit: PropTypes.string,
    is_decimal: PropTypes.bool,
    unit_type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      unit_type: PropTypes.string,
    }),
  }).isRequired,
};

export default DetailsTableDesktop;
