import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../config/basequery';
// eslint-disable-next-line import/no-cycle
import {
  onLoadingUsers,
  editUsers,
  deleteUsers,
  selectNomenclatures,
  selectUserComms,
  editUserComm,
} from './userSlice';

const getUsers = createAsyncThunk('users/getUsers', async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}account/v1/users`,
      config()
    );
    return data.data;
  } catch (error) {
    return error;
  }
});
export default getUsers;

export const createUser = (form, navigateFunc = () => {}) => async (dispatch) => {
  dispatch(onLoadingUsers());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}account/v1/register`,
      form,
      config()

    );

    toast.success(data.message);
  } catch (data) {
    toast.error(data.response.data.message);
  } finally {
    navigateFunc('/user');
  }
};

export const editsUser =
  ({ id, _body }, navigateFunc = () => {}) =>
    async (dispatch) => {
      dispatch(onLoadingUsers());
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}account/v1/update/${id}`,
          _body,
          config()
        );
        dispatch(editUsers(data.data));
        toast.success(data.message);
        navigateFunc('/user');
        return;
      } catch (data) {
        toast.error(data.response.data.message);
      }
    };

export const getNomenclatures = () => async (dispatch) => {
  dispatch(onLoadingUsers());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/nomenclatures`,
      config()
    );
    dispatch(selectNomenclatures(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getUserComms = (id) => async (dispatch) => {
  dispatch(onLoadingUsers());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/userComm/${id}`,
      config()
    );
    dispatch(selectUserComms(data.data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const editsUserComm =
  ({ id, _body }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_BACKEND}api/userComm/${id}`,
          _body,
          config()
        );
        dispatch(editUserComm(data.data));
        toast.success(data.message);
        return;
      } catch (data) {
        toast.error(data.message);
      }
    };

export const deletesUser = (id) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BACKEND}account/v1/delete/${id}`,
      config()
    );
    dispatch(deleteUsers(id));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getRoles = createAsyncThunk('users/getRoles', async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}account/v1/user-roles`,
      config()
    );
    return data.data;
  } catch (error) {
    return error;
  }
});
