export default function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let csv = '';
  array.forEach((item) => {
    const values = Object.values(item).map((value) =>
      typeof value === 'string' && value.includes(',') ? `"${value}"` : value
    );
    csv += `${values.join(',')}\n`;
  });

  return csv;
}
