import calculateCommission from './calculateCommission';

const BONUS = 0.01;

const INITIAL_ITEM_PRICE_VALUES = {
  itemPrice: 0,
  itemBonification: 0,
  itemCommission: 0,
};

const UNIT_TYPES = {
  UNITARY: 1,
  LENGTH: 2,
  SURFACE: 3,
  VOLUME: 4,
  DISTANCE: 5,
  TIME: 6,
};

function calculateItemPrice(item, formState, commissions, client) {
  if ((!item, !formState, !commissions, !client)) {
    return INITIAL_ITEM_PRICE_VALUES;
  }

  const itemValues = {
    ...INITIAL_ITEM_PRICE_VALUES,
  };

  const price = parseFloat(item?.price);
  const width = parseFloat(item?.width);
  const height = parseFloat(item.height);
  const quantity = parseFloat(item?.quantity);

  const isBonus = item?.detail?.nomenclature?.bonusable;
  const unitTypeId = item?.detail?.measurement_unit?.unit_type?.id;

  if (unitTypeId === UNIT_TYPES.LENGTH) {
    itemValues.itemPrice = price * width * quantity;
  } else if (unitTypeId === UNIT_TYPES.SURFACE) {
    itemValues.itemPrice = price * width * height * quantity;
  } else if (unitTypeId === UNIT_TYPES.VOLUME) {
    const depth = parseFloat(item?.depth);
    itemValues.itemPrice = price * width * height * depth * quantity;
  } else {
    itemValues.itemPrice = price * quantity;
  }

  if (isBonus) {
    const bonus = itemValues.itemPrice * parseFloat(item.bonification) * BONUS;
    const nomenclatureId = item?.detail?.nomenclature?.id;

    const commissionPercent = calculateCommission(
      nomenclatureId,
      formState,
      commissions,
      client
    );

    itemValues.itemBonification = bonus;
    itemValues.itemCommission =
      (itemValues.itemPrice - bonus) * commissionPercent * BONUS;
  }
  return itemValues;
}

export default calculateItemPrice;
