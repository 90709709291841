import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { clientOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import getClient, {
  editsAuditComm,
  getAuditsComms,
} from '../../../data/redux/Clients/thunks';
import { setLoading } from '../../../data/redux/Clients/clientSlice';
import Spinner from '../Spinner/Spinner';
import ClientDesktop from './components/ClientDesktop';
import ClientMobile from './components/ClientMobile';

function Client() {
  const dispatch = useDispatch();
  const { loading, auditComm } = useSelector((state) => state.client);
  const { currentPageOrder, orderColumn } = useSelector((state) => state.order);
  const [comission, setComission] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 860);

  useEffect(() => {
    dispatch(getClient());
    dispatch(getAuditsComms());
    dispatch(setLoading(true));
  }, [currentPageOrder, orderColumn]);

  useEffect(() => {
    if (auditComm && auditComm?.length > 0) {
      const comm = auditComm.reduce((ac, register) => {
        const updated = {
          ...ac,
          [register.client_id]: register,
        };
        return updated;
      }, {
      });
      setComission(Object.values(comm));
    } else if (auditComm?.length === 0) {
      setComission(null);
    }
  }, [auditComm]);

  const handleSubmit = (e, clientId, response) => {
    e.preventDefault();
    const newForm = {
      reset: false,
      approved: response,
    };

    dispatch(editsAuditComm({
      id: clientId, _body: newForm
    }));
    dispatch(getAuditsComms());
  };

  useEffect(() => {
    const desktopMediaQuery = window.matchMedia('(min-width: 860px)');

    const updateIsMobile = () => {
      setIsMobile(!desktopMediaQuery.matches);
    };

    updateIsMobile();

    desktopMediaQuery.addEventListener('change', updateIsMobile);

    return () => {
      desktopMediaQuery.removeEventListener('change', updateIsMobile);
    };
  }, []);

  return !loading ? (
    <div className="container-main-client">
      <NavSec title="CLIENTES" content={ clientOptions } />
      <div className="container-client">
        {comission !== null && (
          <div className="container-comission-form">
            <div className="form-head">COMISIONES ESPECIALES</div>
            {comission.map((item) => (
              <form key={ item.id } className="form-comission" action="">
                <p className="text-label">
                  {item.client_id} -{' '}
                  {item.client?.company === ''
                    ? item.client?.fullname
                    : item.client?.company}
                </p>
                <p className="text-label">% {item.commission}</p>
                <button
                  className="btn-comission"
                  type="button"
                  onClick={ (e) => handleSubmit(e, item.client_id, true) }
                >
                  Aprobar
                </button>
                <button
                  className="btn-comission"
                  type="button"
                  onClick={ (e) => handleSubmit(e, item.client_id, false) }
                >
                  Rechazar
                </button>
              </form>
            ))}
          </div>
        )}
        {!isMobile ? <ClientDesktop /> : <ClientMobile />}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default Client;
