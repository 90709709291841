import React from 'react';

function Spinner() {
  return (
    <div className="loadingWindow">
      <div className="spinner">
        <div className="spinner2">.</div>
      </div>
    </div>
  );
}

export default Spinner;
