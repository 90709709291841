import { createSlice } from '@reduxjs/toolkit';

import { getQuotes } from './getQuotes';

export const quoteSlice = createSlice({
  name: 'quote',
  initialState: {
    quote: [],
    editOrders: null,
    currentOrder: null,
    loading: true,
    client: null,
  },

  reducers: {
    addQuote: (state, { payload }) => {
      state.quote = [...state.quote, {
        ...payload
      }];
      state.editOrders = null;
    },

    editOrder: (state, { payload }) => {
      state.order = state.order.map((order) =>
        order.id === payload.id ? payload : order
      );
      state.editOrders = null;
    },

    deleteQuotes: (state, { payload }) => {
      state.quote = state.quote.filter((quote) => quote.id !== payload);
      state.loading = false;
    },

    selectOrder: (state, { payload }) => {
      if (state?.length > 0) {
        state.editOrders = state.order.find((order) => order.id === payload.id);
      } else {
        state.editOrders = payload;
      }
    },

    onLoadingOrder: (state) => {
      state.loading = true;
    },

    editOrders: (state, { payload }) => {
      state.order = payload;
    },

    selectedClient: (state, { payload }) => {
      state.client = payload;
    },

    changeLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuotes.fulfilled, (state, { payload }) => {
      state.quote = payload;
      state.editOrders = null;
      state.currentOrder = null;
      state.client = null;
      state.loading = false;
    });
    builder.addCase(getQuotes.rejected, (state) => {
      state.quote = 'fail';
      state.editOrders = null;
    });
  },
});

export const {
  addQuote,
  editOrder,
  deleteQuotes,
  selectOrder,
  selectedClient,
  onLoadingOrder,
  editOrders,
  changeLoading,
} = quoteSlice.actions;

export const quoteReducer = quoteSlice.reducer;
