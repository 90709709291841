import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuditComms,
  getClientComms,
  getNomenclatures,
} from '../../../../data/redux/Clients/thunks';
import { clientOptions } from '../../../../helpers/dataOptions';
import NavSec from '../../NavSec/NavSec';
import ClientFormComm from './ClientFormComm';
import Spinner from '../../Spinner/Spinner';
import ClientFormData from './ClientFormData';
import ClientFormSpecialComm from './ClientFormSpecialComm';

function ClientForm() {
  const { editClients, loading, nomenclature } = useSelector(
    (state) => state.client
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    nomenclature?.length === 0 && dispatch(getNomenclatures());

    if (editClients) {
      dispatch(getClientComms(editClients.id));
      dispatch(getAuditComms(editClients.id));
    }
  }, []);

  return !loading ? (
    <div className="container-form-main">
      <NavSec title="CLIENTES" content={ clientOptions } />
      <div className="container-form-base">
        <ClientFormData />
        {editClients?.client_type.id === 2 && (
          <div className="container-main-com-client">
            <ClientFormComm />
            <ClientFormSpecialComm />
          </div>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default ClientForm;
