import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

// eslint-disable-next-line import/no-cycle
import { onLoadingUserConfig } from './userConfigSlice';
import config from '../config/basequery';

export const getConfig = createAsyncThunk(
  'userconfig/getuserconfig',
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BACKEND}api/config`,
        config()
      );
      return {
        ...data.config
      };
    } catch (data) {
      return data.message;
    }
  }
);
export default getConfig;

export const editConfig =
  ({ _body }) =>
    async (dispatch) => {
      dispatch(onLoadingUserConfig({
        loading: true
      }));
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_BACKEND}api/config`,
          _body,
          config()
        );
        toast.success(data.message);
      } catch (error) {
        toast.error(error.message);
      } finally {
        dispatch(onLoadingUserConfig({
          loading: false
        }));
      }
    };
// export default getConfig
