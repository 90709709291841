import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sectionsOptions } from '../../../helpers/dataOptions';

import getOrdersStatuses from '../../../data/redux/OrderStatuses/thunks';
import Spinner from '../Spinner/Spinner';
import getItem from '../../../data/redux/Items/thunks';
import { setLoading } from '../../../data/redux/Items/itemSlice';

function HomeComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { displayName, permissions } = useSelector(
    (state) => state.auth
  );
  const { loading } = useSelector((state) => state.item);
  
  useEffect(() => {
    dispatch(getOrdersStatuses());
    dispatch(getItem());
    dispatch(setLoading());
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 860);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = (path) => {
    navigate(path);
  };

  return !loading ? (
    <div className="container-main-home">
      <div className="hello-Us">
        <h1>Bienvenidos</h1>
        <p>{displayName}</p>
      </div>
      <div className="col-button">
        {sectionsOptions.map((section) => 
          <div
            key={ section.path }
            onClick={ () => { 
              if (permissions.includes(section.permission)) {
                handleClick(section.path); 
              }} }
            type="button"
            className={
              permissions.includes(section.permission)
                ? 'bnt-home'
                : 'bnt-home-out'
            }
            style={ isMobile ? {
              backgroundColor: 'white'
            }: {
              backgroundImage: `url(${process.env.REACT_APP_URL + section.image})` 
            } }
          >
            <div className="container-card-home-description">
              <h2 className="title-button-home">{section.section}</h2>
              <span className="material-icons-round">arrow_forward_ios</span>
              <div className="description-card">
                <h2 className="title-button-home-card">{section.section}</h2>
                <p className="text-description-home">{section.description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default HomeComponent;
