import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  startChangePassword,
  startLogout,
} from '../../../data/redux/auth/thunks';
import { startCleanMU } from '../../../data/redux/Units/thunks';
import { useChangePasswordValidator } from '../../hooks/useChangePasswordValidator';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';

function ConfigUser() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();

  const { formState, setFormState } = useForm({
    password: '',
    newPassword: '',
    repeatPassword: '',
  });

  const { errors, validateForm, onBlurField } =
    useChangePasswordValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const logout = () => {
    dispatch(startCleanMU());
    dispatch(startLogout(auth));
  };

  const uploadPassword = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return;

    dispatch(startChangePassword(auth, formState, navigate));
  };

  return (
    <div className="container-config">
      <div className="container-config-background">.</div>
      <div className="container-config-user">
        <h2 className="title-config">CONFIGURACIONES</h2>
        <h3 className="title-config-btn">CONTRASEÑA</h3>
        <form action="" className="form-config" onSubmit={ uploadPassword }>
          <div className="input-config">
            <InputField
              label="CONTRASEÑA ACTUAL"
              name="password"
              type="password"
              value={ formState.password }
              onChange={ onInputChange }
              onBlur={ onBlurField }
              className={ errors.password.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-form">
            {errors.password.dirty && errors.password.error ? (
              <p className="error-validation-w">{errors.password.message}</p>
            ) : null}
          </div>
          <div className="input-config">
            <InputField
              label="CONTRASEÑA NUEVA"
              name="newPassword"
              type="password"
              value={ formState.newPassword }
              onChange={ onInputChange }
              onBlur={ onBlurField }
              className={ errors.newPassword.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-form">
            {errors.newPassword.dirty && errors.newPassword.error ? (
              <p className="error-validation-w">{errors.newPassword.message}</p>
            ) : null}
          </div>
          <div className="input-config">
            <InputField
              label="REPETIR CONTRASEÑA"
              name="repeatPassword"
              type="password"
              value={ formState.repeatPassword }
              onChange={ onInputChange }
              onBlur={ onBlurField }
              className={ errors.repeatPassword.error ? 'red-advertise' : '' }
            />
          </div>
          <div className="container-form">
            {errors.repeatPassword.dirty && errors.repeatPassword.error ? (
              <p className="error-validation-w">
                {errors.repeatPassword.message}
              </p>
            ) : null}
          </div>
          <div className="container-btn-config">
            <button
              className="save-sesion-btn"
              type="button"
              onClick={ uploadPassword }
            >
              <span className="material-icons-outlined">save</span>GUARDAR
            </button>
            <button className="close-sesion-btn" type="button" onClick={ logout }>
              CERRAR SESIÓN
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfigUser;
