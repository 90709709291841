import React from 'react';
import PropTypes from 'prop-types';
import OrdersFinalCardItem from './OrdersFinalCardItem';

function OrdersFinalCard({ sector }) {
  return (
    <div className="articles-orders-container">
      <h3 className="sector-orders">{`Sector ${sector?.sector?.sector}`}</h3>
      {sector.item_list.map((it) => (
        <OrdersFinalCardItem key={ it.id } item={ it } />
      ))}
      <div className="sector-orders-facility">
        Instalación del Sector {sector?.sector?.sector}: ${' '}
        {sector?.facilities?.toFixed(2)}
      </div>
    </div>
  );
}

OrdersFinalCard.propTypes = {
  sector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    observations: PropTypes.string,
    sector: PropTypes.shape({
      id: PropTypes.number.isRequired,
      sector: PropTypes.string.isRequired,
    }),
    order_id: PropTypes.number.isRequired,
    sector_id: PropTypes.number.isRequired,
    facilities: PropTypes.number.isRequired,
    item_list: PropTypes.arrayOf(PropTypes.shape({
    })),
  }).isRequired,
};

export default OrdersFinalCard;
