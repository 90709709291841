/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getConfig } from './thunks';

export const userConfigSlice = createSlice({
  name: 'userconfig',
  initialState: {
    userConfig: {
    },
    loading: true,
    editConfigs: null,
  },

  reducers: {
    editUserConfig: (state, { payload }) => {
      state.userConfig = payload;
      state.editConfigs = null;
      state.loading = true;
    },
    onLoadingUserConfig: (state) => {
      state.loading = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, { payload }) => {
      state.userConfig = payload;
      state.editConfigs = payload;
      state.loading = false;
    });
    builder.addCase(getConfig.rejected, (state) => {
      state.userConfig = 'fail';
      state.editConfigs = null;
      state.loading = true;
    });
  },
});

export const { editUserConfig, onLoadingUserConfig } = userConfigSlice.actions;

export const userConfigReducer = userConfigSlice.reducer;
