import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import useForm from '../../../hooks/useForm';
import InputField from '../../InputField/InputField';

import {
  createClient,
  editsClient,
} from '../../../../data/redux/Clients/thunks';
import useClientsValidator from '../../../hooks/useClientsValidator';

function ClientFormData() {
  const { editClients } = useSelector((state) => state.client);
  const { formState, setFormState, handleEnableChange } = useForm({
    id: editClients ? editClients.id : '',
    clientType: editClients ? String(editClients.client_type.id) : '',
    fullname: editClients ? editClients.fullname : '',
    cuit: editClients ? editClients.cuit : '',
    address: editClients ? editClients.address : '',
    phone: editClients ? editClients.phone : '',
    email: editClients ? editClients.email : '',
    company: editClients ? editClients.company : '',
    observations: editClients ? editClients.observations : '',
    bonification: editClients ? editClients.bonification : '',
    enabled: editClients ? Boolean(editClients.enabled) : true,
  });

  if (formState?.clientType === '1') {
    formState.company = '';
  }

  const { errors, validateForm, onBlurField } = useClientsValidator(formState);
  const navigate = useNavigate();

  const handleClick = () => navigate(-1);
  const goToClients = () => navigate('/client');

  const dispatch = useDispatch();

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name]?.dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const newForm = {
      id: formState.id,
      client_type_id: Number(formState.clientType),
      fullname: formState.fullname,
      cuit: formState.cuit ? Number(formState.cuit) : '',
      address: formState.address,
      phone: String(formState.phone),
      email: formState.email,
      company: formState.clientType === '2' ? formState.company : '',
      observations: formState.observations,
      bonification: Number(formState.bonification),
      enabled: Boolean(formState.enabled),
      commissions: formState.commissions,
    };

    if (editClients) {
      const editData = {
        id: formState.id, _body: newForm
      }; 
      dispatch(editsClient(editData, goToClients));
    } else {
      dispatch(createClient(newForm, goToClients));
    }
    return null;
  };

  return (
    <div className="clientFormData">
      <div className="container-client-form container-form">
        <form className="form-base" action="" onSubmit={ handleFormSubmit }>
          <div className="div-btn">
            <button onClick={ handleClick } className="btn-close" type="button">
              <span className="material-icons-outlined">close</span>
            </button>
          </div>
          <div className="container-colum-form">
            <div className="mini-container-form">
              <div className="select-input">
                <label className="label-select" htmlFor="as">
                  {' '}
                  <div className="text-label">* TIPO</div>
                  <select
                    className={
                      errors?.clientType?.error
                        ? 'select-opt red-advertise'
                        : 'select-opt'
                    }
                    name="clientType"
                    id="clientType"
                    value={ formState?.clientType }
                    onClick={ () =>
                      setFormState({
                        ...formState,
                        company: '',
                      }) }
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                  >
                    <option className="option-select" default value="">
                      Seleccione un tipo de cliente
                    </option>
                    <option className="option-select" value="1">
                      Final
                    </option>
                    <option className="option-select" value="2">
                      Conector
                    </option>
                  </select>
                </label>
              </div>
              <div className="container-client-form container-form">
                {errors?.clientType?.dirty && errors?.clientType?.error ? (
                  <p className="error-validation">
                    {errors?.clientType?.message}
                  </p>
                ) : null}
              </div>
              <div className="ctn-input">
                <InputField
                  label="* NOMBRE Y APELLIDO"
                  name="fullname"
                  id="fullname"
                  type="text"
                  placeholder="Marmoleria Yerba Buena"
                  value={ formState?.fullname }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors?.fullname?.error ? 'red-advertise' : '' }
                />
              </div>
              <div className="container-client-form container-form">
                {errors?.fullname?.dirty && errors?.fullname?.error ? (
                  <p className="error-validation">
                    {errors?.fullname?.message}
                  </p>
                ) : null}
              </div>
              <div className="ctn-input">
                <InputField
                  label="CUIT/CUIL/DNI"
                  name="cuit"
                  id="cuit"
                  type="number"
                  placeholder="20000000009"
                  value={ formState?.cuit }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors?.cuit?.error ? 'red-advertise' : '' }
                  isDecimal={ false }
                />
              </div>
              <div className="container-client-form container-form">
                {errors?.cuit?.dirty && errors?.cuit?.error ? (
                  <p className="error-validation">{errors?.cuit?.message}</p>
                ) : null}
              </div>
              <div className="ctn-input">
                <InputField
                  label="EMAIL"
                  name="email"
                  id="email"
                  type="text"
                  placeholder="marmoleria@marmoleria.com"
                  value={ formState?.email }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                />
              </div>
            </div>
            <div className="mini-container-form">
              {formState.clientType === '2' ? (
                <>
                  <div className="ctn-input">
                    <InputField
                      label="* EMPRESA"
                      name="company"
                      id="company"
                      type="text"
                      placeholder="MYB"
                      value={ formState?.company }
                      onBlur={ onBlurField }
                      onChange={ onInputChange }
                      className={ errors?.company.error ? 'red-advertise' : '' }
                    />
                  </div>
                  <div className="container-client-form container-form">
                    {errors?.company?.dirty && errors?.company?.error ? (
                      <p className="error-validation">
                        {errors?.company?.message}
                      </p>
                    ) : null}
                  </div>
                </>
              ) : null}
              <div className="ctn-input">
                <InputField
                  label="DIRECCIÓN"
                  name="address"
                  id="address"
                  type="text"
                  placeholder="Av. Solano Vera 1174"
                  value={ formState?.address }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                />
              </div>
              <div className="ctn-input">
                <InputField
                  label="* TELÉFONO"
                  name="phone"
                  id="phone"
                  type="number"
                  min={ 0 }
                  placeholder="381 6558899"
                  value={ formState?.phone }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors?.phone.error ? 'red-advertise' : '' }
                  isDecimal={ false }
                />
              </div>
              <div className="container-client-form container-form">
                {errors?.phone?.dirty && errors?.phone?.error ? (
                  <p className="error-validation">{errors?.phone?.message}</p>
                ) : null}
              </div>
              <div className="ctn-input">
                <InputField
                  label="% BONIFICACIONES"
                  name="bonification"
                  id="bonification"
                  type="number"
                  min={ 0 }
                  placeholder={ 5 }
                  value={ formState?.bonification }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors?.bonification?.error ? 'red-advertise' : '' }
                />
              </div>
              <div className="container-client-form container-form">
                {errors?.bonification?.dirty && errors?.bonification?.error ? (
                  <p className="error-validation">
                    {errors?.bonification?.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="ctn-input unit-textArea">
            <label htmlFor="a">
              DESCRIPCIÓN
              <textarea
                name="observations"
                id="observations"
                placeholder="Descripción"
                value={ formState?.observations }
                onChange={ onInputChange }
              />
            </label>
          </div>
          <label className="ctn-check" htmlFor="a">
            *HABILITADO
            <input
              id="enabled"
              name="enabled"
              className="inp-check"
              type="checkbox"
              defaultChecked={ formState?.enabled }
              onBlur={ onBlurField }
              onChange={ handleEnableChange }
            />
          </label>
          <div className='buttonSaveContainer'>
            <button className="btn-save" type="submit">
              <span className="material-icons-outlined">save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ClientFormData;
