import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { measurementUnitReducer } from '../Units/measurementUnitSlice';
import { userConfigReducer } from '../UserConfig/userConfigSlice';
import { authSlice } from '../auth/authSlice';
import { clientReducer } from '../Clients/clientSlice';
import { itemReducer } from '../Items/itemSlice';
import { userReducer } from '../users/userSlice';
import { paymentReducer } from '../payments/paymentSlice';
import { orderReducer } from '../Orders/orderSlice';
import { reportReducer } from '../Reports/reportSlice';
import { orderStatusesReducer } from '../OrderStatuses/orderStatusesSlice';
import { quoteReducer } from '../Quotes/quoteSlice';

// eslint-disable-next-line import/named

const persistAuthConfig = {
  key: 'auth',
  storage,
};
const persistOtherConfig = {
  key: 'other',
  storage,
};
const persistMeasurementUnit = {
  key: 'measurementunit',
  storage,
};
const persistItem = {
  key: 'item',
  storage,
};
const persistClient = {
  key: 'client',
  storage,
};
const persistUser = {
  key: 'user',
  storage,
};

const persistUserConfig = {
  key: 'userconfig',
  storage,
};

const persistPayment = {
  key: 'payment',
  storage,
};

const persistOrder = {
  key: 'order',
  storage,
};

const persistQuote = {
  key: 'quote',
  storage,
};

const persistOrderStatuses = {
  key: 'ordersStatuses',
  storage,
};

const persistReport = {
  key: 'report',
  storage,
};

const persistedAuthReducer = persistReducer(
  persistAuthConfig,
  authSlice.reducer
);
const persistedOtherReducer = persistReducer(
  persistOtherConfig,
  authSlice.reducer
);
const persistMeasurementUnitReducer = persistReducer(
  persistMeasurementUnit,
  measurementUnitReducer
);
const persistItemReducer = persistReducer(persistItem, itemReducer);
const persistClientReducer = persistReducer(persistClient, clientReducer);
const persistUserReducer = persistReducer(persistUser, userReducer);

const persistUserConfigReducer = persistReducer(
  persistUserConfig,
  userConfigReducer
);

const persistPaymentReducer = persistReducer(persistPayment, paymentReducer);
const persistOrderReducer = persistReducer(persistOrder, orderReducer);
const persistQuoteReducer = persistReducer(persistQuote, quoteReducer);
const persistOrderStatusesReducer = persistReducer(
  persistOrderStatuses,
  orderStatusesReducer
);
const persistReportReducer = persistReducer(persistReport, reportReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    other: persistedOtherReducer,
    measurementunit: persistMeasurementUnitReducer,
    client: persistClientReducer,
    user: persistUserReducer,
    item: persistItemReducer,
    userconfig: persistUserConfigReducer,
    payment: persistPaymentReducer,
    order: persistOrderReducer,
    quote: persistQuoteReducer,
    orderStatuses: persistOrderStatusesReducer,
    report: persistReportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
