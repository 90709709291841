import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import NavSec from '../NavSec/NavSec';
import { ordersOptions } from '../../../helpers/dataOptions';
import Table from '../Table/Table';
import createPayment from '../../../data/redux/payments/thunks';
import useForm from '../../hooks/useForm';
import { getCurrencies } from '../../../data/redux/Items/thunks';
import getPaymentTerms from '../../../data/redux/payments/paymentTerms';
import usePaymentsValidator from '../../hooks/usePaymentsValidator';
import columnsTablePayment from './Tables/columnsTablePayment';
import DetailsTablePayment from './components/DetailsTablePayment';
import PaymentsFormFields from './components/PaymentsFormFields';
import { CURRENCIES } from './utils/constants';

function Payment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currency } = useSelector((state) => state.item);
  const { editOrders } = useSelector((state) => state.order);
  const { permissions } = useSelector((state) => state.auth);
  const [disableButtonSave, setDisableButtonSave] = useState(false);

  const [adjustmentPayment, setAdjustmentPayment] = useState(false);

  const calculateDefaultStartDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 10);
  };
  const handleClose = () => {
    navigate(-1);
  };

  const { formState, setFormState } = useForm({
    paymentDate: calculateDefaultStartDate(),
    paymentAmount: '',
    currencyId: 0,
    observations: '',
    adjustment: false,
    exchangeRate: '',
    orderId: editOrders ? editOrders.id : 0,
    paymentTermId: '',
    commissionPaid: editOrders ? editOrders.commission_paid : 0,
  });

  const { errors, validateForm } = usePaymentsValidator(formState);

  useEffect(() => {
    dispatch(getPaymentTerms());

    if (currency?.length === 0) dispatch(getCurrencies());
  }, []);

  useEffect(() => {
    setFormState({
      ...formState,
      adjustment: adjustmentPayment,
    });
  }, [adjustmentPayment]);

  useEffect(() => {
    setFormState({
      ...formState,
      commissionPaid: editOrders.commission_paid,
    });
  }, [editOrders]);

  function checkExceededPay() {
    if (formState.exchangeRate) {

      if (formState.currencyId === CURRENCIES.peso) {
        return Number(formState.paymentAmount) / Number(formState.exchangeRate) > editOrders.dollar_balance;
      }

      return Number(formState.paymentAmount) * Number(formState.exchangeRate) > editOrders.peso_balance;
    }

    if (formState.currencyId === CURRENCIES.peso) {
      return Number(formState.paymentAmount) > editOrders.peso_balance;
    }

    return Number(formState.paymentAmount) > editOrders.dollar_balance;

  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setDisableButtonSave(true);
    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) {
      setDisableButtonSave(false);
      return toast.error('Los datos ingresados no son válidos');
    }
    const newForm = {
      paymentDate: formState.paymentDate,
      paymentAmount: Number(formState.paymentAmount),
      observations: formState.observations,
      currencyId: Number(formState.currencyId),
      exchangeRate: Number(formState.exchangeRate),
      adjustment: Boolean(formState.adjustment),
      orderId: Number(formState.orderId),
      paymentTermId: Number(formState.paymentTermId),
    };

    const exceededPay = checkExceededPay();

    if (exceededPay) {
      const toastId = toast(
        <div className="flex-column">
          <article className="close-order-confirm">
            <button
              type="button"
              className="btn-close mb-4"
              onClick={ () => toast.remove() }
            >
              <span className="material-icons-outlined">close</span>
            </button>
          </article>
          <div className="text-center">
            No se puede realizar un pago por encima del monto adeudado
            <br />
            (Recuerde que se puede utilizar el campo cotización para saldar otra cuenta)
          </div>
          <br />
          <div className="order-confirm">
            <button
              type="button"
              className=""
              onClick={ () => toast.dismiss(toastId) }
            >
              OK
            </button>
          </div>
        </div>,
        {
          duration: 20000,
        }
      );
    } else {
      return dispatch(createPayment(newForm, navigate));
    }
    setDisableButtonSave(false);
    return null;
  };

  return (
    <div className="container-main-orders-payment">
      <NavSec title="PEDIDOS" content={ ordersOptions } />
      <div className="container-filter">
        <button className="btn-filter" type="button">
          <Link to="form">
            <span className="material-icons-outlined">playlist_add</span>
          </Link>
        </button>
        <input className="inp-filter" type="serch" />
        <button className="btn-filter" type="button">
          <span className="material-icons-outlined">search</span>
        </button>
      </div>
      <Table
        className="marmoleria--datatable table-payments"
        selectRows={ false }
        columnsTable={ columnsTablePayment }
        filteredData={ [editOrders] }
        detailsTable={ DetailsTablePayment }
        section="order"
        pagination={ false }
      />
      <form className="container-main-payment" onSubmit={ handleFormSubmit }>
        <PaymentsFormFields
          formState={ formState }
          setFormState={ setFormState }
          setAdjustmentPayment={ setAdjustmentPayment }
        />
        <div className="container-btn-payment">
          <button
            onClick={ handleClose }
            className="btn-save btn-flex"
            type="button"
          >
            <span className="material-icons-outlined">arrow_back_ios</span>
          </button>
          {permissions.includes('order-read') ? (
            <button className="btn-save btn-flex" type="submit" disabled={ disableButtonSave }>
              <span className="material-icons-outlined">save</span>
            </button>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default Payment;
