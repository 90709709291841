function calcularCantidadItemsSector(sect) {
  let items = 0;
  sect.item_list.forEach((it) => {
    if (it.quantity) {
      items += it.quantity;
    } else {
      items += 1;
    }
  });
  return items;
}

export default calcularCantidadItemsSector;
