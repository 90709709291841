import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from '../config/basequery';

import {
  deleteQuotes,
  onLoadingOrder,
} from './quoteSlice';
import { addOrder } from '../Orders/orderSlice';

export const deleteQuote = (id) => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BACKEND}api/quote_request/${id}`,
      config()
    );
    dispatch(deleteQuotes(id));
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const acceptQuote = (id) => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_BACKEND}api/quote_request/${id}`,
      {
        id
      },
      config()
    );
    dispatch(deleteQuotes(id));
    dispatch(addOrder(data.data));
    toast.dismiss();
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.response.data.message);
  }
};

