import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import usePaymentsValidator from '../../../hooks/usePaymentsValidator';
import { markCommOrder } from '../../../../data/redux/Orders/thunks';
import InputField from '../../InputField/InputField';

function PaymentsFormFields({ formState, setAdjustmentPayment, setFormState }) {
  const dispatch = useDispatch();
  const { paymentTerms } = useSelector((state) => state.payment);
  const { editOrders } = useSelector((state) => state.order);
  const { currency } = useSelector((state) => state.item);
  const { errors, validateForm, onBlurField } = usePaymentsValidator(formState);
  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState(nextFormState);

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const onSwitchPaymentChange = () => {
    setAdjustmentPayment((previousState) => !previousState);
  };

  const handleConfirmMarkComm = async (order) => {
    try {
      dispatch(markCommOrder(order));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleMarkComm = async () => {
    if (editOrders.commission_paid) {
      return;
    }

    const toastId = toast(
      <div className="flex-column">
        <article className="close-order-confirm">
          <button
            type="button"
            className="btn-close mb-4"
            onClick={ () => toast.remove() }
          >
            <span className="material-icons-outlined">close</span>
          </button>
        </article>
        <div className="text-center">
          ¿Estás seguro de marcar la comisión del pedido como pagada?
          <br />
          (Este cambio no se podrá deshacer en el futuro)
        </div>
        <br />
        <div className="order-confirm">
          <button
            type="button"
            className=""
            onClick={ () => handleConfirmMarkComm(editOrders) }
          >
            Si
          </button>
          <button
            type="button"
            className=""
            onClick={ () => toast.dismiss(toastId) }
          >
            No
          </button>
        </div>
      </div>,
      {
        duration: 20000,
      }
    );
  };

  return (
    <>
      <div className="container-detail-main-orders">
        <div className="container-colum-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Fecha de pago:</h3>
            <input
              name="paymentDate"
              id="paymentDate"
              type="date"
              onBlur={ onBlurField }
              onChange={ onInputChange }
              value={ formState?.paymentDate }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.paymentDate.dirty && errors.paymentDate.error ? (
              <p className="error">{errors.paymentDate.message}</p>
            ) : null}
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Condición de pago:</h3>
            <select
              type="number"
              className="select-opt"
              name="paymentTermId"
              id="paymentTermId"
              onBlur={ onBlurField }
              onChange={ onInputChange }
            >
              <option className="option-select" default value="">
                Seleccione un tipo de pago
              </option>
              {paymentTerms.payments?.map((pay) => (
                <option className="option-select" value={ pay.id } key={ pay.id }>
                  {pay.payment_term}
                </option>
              ))}
            </select>
          </div>
          <div className="container-client-form container-form">
            {errors.paymentTermId.dirty && errors.paymentTermId.error ? (
              <p className="error">{errors.paymentTermId.message}</p>
            ) : null}
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Cotización:</h3>
            <input
              name="exchangeRate"
              id="exchangeRate"
              type="number"
              onBlur={ onBlurField }
              onChange={ onInputChange }
              min={ 0 }
            />
          </div>
        </div>
        <div className="container-colum-orders">
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Moneda:</h3>
            <select
              name="currencyId"
              id="currencyId"
              onChange={ onInputChange }
              onBlur={ onBlurField }
            >
              <option className="option-select" default value="">
                Seleccione un tipo de moneda
              </option>
              {currency?.map((curren) => (
                <option value={ curren?.id } key={ curren.id }>
                  {curren?.currency}
                </option>
              ))}
            </select>
          </div>
          <div className="container-client-form container-form">
            {errors.currencyId.dirty && errors.currencyId.error ? (
              <p className="error">{errors.currencyId.message}</p>
            ) : null}
          </div>
          <div className="detail-data-orders">
            <h3 className="p-title-detail">Importe:</h3>

            <InputField
              name="paymentAmount"
              type="amount"
              onBlur={ onBlurField }
              onChange={ onInputChange }
              className={
                errors.paymentAmount.error
                  ? 'input-pc red-advertise'
                  : 'input-pc'
              }
              value={ formState?.paymentAmount }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.paymentAmount.dirty && errors.paymentAmount.error ? (
              <p className="error">{errors.paymentAmount.message}</p>
            ) : null}
          </div>
          <div className="container-check-adjustment">
            <label className="ctn-check" htmlFor="a">
              AJUSTES
              <input
                id="adjustment"
                name="adjustment"
                className="inp-check"
                type="checkbox"
                onBlur={ onBlurField }
                onClick={ onSwitchPaymentChange }
                value={ formState?.adjustment }
              />
            </label>
          </div>
          <div className="ctn-check">
            COMISIÓN PAGADA
            <button type="button" onClick={ handleMarkComm } className="btn-comn">
              {!editOrders.commission_paid ? (
                <span className="material-icons-outlined comm-no-paid">
                  close
                </span>
              ) : (
                <span className="material-icons-outlined comm-paid">done</span>
              )}
            </button>
          </div>
        </div>
      </div>
      <textarea
        className="text-area-payment"
        placeholder="Detalle"
        name="observations"
        id="observations"
        onChange={ onInputChange }
      />
    </>
  );
}

PaymentsFormFields.propTypes = {
  formState: PropTypes.shape({
    paymentDate: PropTypes.string.isRequired,
    paymentAmount: PropTypes.string.isRequired,
    currencyId: PropTypes.number.isRequired,
    observations: PropTypes.string.isRequired,
    adjustment: PropTypes.bool.isRequired,
    exchangeRate: PropTypes.string.isRequired,
    orderId: PropTypes.number.isRequired,
    paymentTermId: PropTypes.string.isRequired,
    commissionPaid: PropTypes.number.isRequired,
  }).isRequired,
  setFormState: PropTypes.func.isRequired,
  setAdjustmentPayment: PropTypes.func.isRequired,
};

export default PaymentsFormFields;
