import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import Table from '../Table/Table';
import columnsTableDesktop from './Tables/columnsTableDesktop';
import DetailsTableDesktop from './components/DetailsTableDesktop';

const conditionalStyles = [
  {
    when: (row) => !row.enabled,
    style: {
      color: '#c5b6b6',
    },
  },
];

function UserComponent() {
  const { user } = useSelector((state) => state.user);
  useEffect(() => { }, []);

  return (
    <Table
      className="marmoleria--datatable user-datatable"
      selectRows={ false }
      columnsTable={ columnsTableDesktop }
      filteredData={ user }
      detailsTable={ DetailsTableDesktop }
      filterEnabled
      conceptsFilter={ [
        'fullName',
        'CUIT',
        'email',
        'address',
        'phone',
        'roles.name',
        'commissions.commissions',
      ] }
      conditionalStyles={ conditionalStyles }
      messageNoData={ <h1 className="no-data-message">NO SE ENCONTRARON USUARIOS</h1> }
      section="user"
      lastPageByFilteredData
    />
  );
}

export default UserComponent;
