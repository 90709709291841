import useQuotes from '../hooks/useQuotes';
import ButtonQuotesMod from './ButtonQuotesMod';

function QuotesColumnsMobile() {
  const { quotes } = useQuotes();
  
  const totalItems = (row) => {
    let total = 0;
    row.sectors.forEach(element => {
      total += element.item_list.length;
    });
    return total;
  };

  const columnsTableMobile = [
    {
      name: 'N°',
      selector: (row) => row?.origin_order,
      sortable: true,
      width: '8%',
    },
    {
      name: 'CONTACTO',
      selector: (row) =>
        row?.client.fullname,
      sortable: true,
      width: '31.5%',
    },
    {
      name: 'FECHA',
      selector: (row) =>
        row?.start_date.slice(0, 10),
      sortable: true,
      width: '24%',
    },
    {
      name: 'ARTICULOS',
      selector: (row) => `${totalItems(row)} Artículo(s)`,
      sortable: false,
      width: '24%',
    },
    {
      name: '',
      cell: () => (
        null
      ),
      sortable: true,
      button: true,
      width: '0%',
    },
    {
      name: '',
      cell: (row) => ButtonQuotesMod(row),
      sortable: true,
      button: true,
      width: '5%',
    },
  ];
  return {
    columnsTableMobile, quotes
  };
}

export default QuotesColumnsMobile;
