import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { setLastPage } from '../Orders/orderSlice';

import config from '../config/basequery';

export const getQuotes = createAsyncThunk('order/getQuotes', async (_, { getState, dispatch }) => {
  try {
    const state = getState();
    const { currentPageOrder, orderColumn } = state.order;
    const query = `?quotes=true&per_page=10&page=${currentPageOrder+1}&column_name=${orderColumn.column || 'id'}&column_order=${orderColumn.order || 'desc'}`;
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/orders${query}`,
      config()
    );

    dispatch(setLastPage(data.orders.last_page));
    return data?.orders?.data;
  } catch (error) {
    toast(error);
    return [];
  }
});
