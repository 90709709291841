import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';
import { articleOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import {
  createItem,
  editsItem,
  getCurrencies,
  getItemTypes,
  getMUnits,
  getNomenclatures,
} from '../../../data/redux/Items/thunks';
import useItemsValidator from '../../hooks/useItemsValidator';
import Spinner from '../Spinner/Spinner';
import getMeasurementUnit from '../../../data/redux/Units/thunks';

function ArticlesForm() {
  const dispatch = useDispatch();
  const [loadingStatuses, setLoadingStatuses] = useState(true);

  const { nomenclature, itemType, currency, editItems } = useSelector(
    (state) => state.item
  );

  const { measurementunit } = useSelector((state) => state.measurementunit);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        dispatch(getMeasurementUnit()),
        dispatch(getItemTypes()),
        dispatch(getNomenclatures()),
        currency?.length === 0 && dispatch(getCurrencies()),
        dispatch(getMUnits()),
      ]);
      setLoadingStatuses(false);
    };
    loadData();
  }, []);

  const { formState, setFormState } = useForm({
    id: editItems ? editItems.id : '',
    item: editItems ? editItems.item : '',
    nomenclatureId: editItems ? String(editItems.nomenclature.id) : '',
    measurementUnitId: editItems ? String(editItems.measurement_unit.id) : '',
    width: editItems ? editItems.width : '',
    height: editItems ? editItems.height : '',
    depth: editItems ? editItems.depth : '',
    currencyId: editItems ? String(editItems.currency.id) : '',
    cost: editItems ? editItems.cost : '',
    price: editItems ? editItems.price : '',
    description: editItems ? editItems.description : '',
    enabled: editItems ? Boolean(editItems.enabled) : true,
    itemType: editItems ? String(editItems.nomenclature.item_type_id) : '',
  });

  const { errors, validateForm, onBlurField } = useItemsValidator(formState);

  const navigate = useNavigate();

  const handleClick = () => navigate(-1);
  const goToArticles = () => navigate('/article');

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };
    setFormState(nextFormState);

    if (errors[name]?.dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const enabled =
      typeof formState.enabled === 'boolean'
        ? formState.enabled
        : formState.enabled === 'true';
    const newForm = {
      id: formState.id,
      item: formState.item,
      nomenclature_id: Number(formState.nomenclatureId),
      measurement_unit_id: Number(formState.measurementUnitId),
      width:
        formState.itemType === '1' || formState.itemType === '2'
          ? Number(formState.width)
          : '',
      height:
        formState.itemType === '1' || formState.itemType === '2'
          ? Number(formState.height)
          : '',
      depth:
        formState.itemType === '1' || formState.itemType === '2'
          ? Number(formState.depth)
          : '',
      currency_id: Number(formState.currencyId),
      cost: Number(formState.cost),
      price: Number(formState.price),
      description: formState.description,
      enabled,
    };

    if (editItems) {
      const editData = {
        id: formState.id, _body: newForm
      };
      dispatch(editsItem(editData, goToArticles));
    } else {
      dispatch(createItem(newForm, goToArticles));
    }
    return null;
  };

  return !loadingStatuses ? (
    <div className="container-main-form-article">
      <NavSec title="ARTÍCULOS" content={ articleOptions } />
      <div className="container-client-form container-form">
        <form className="form-base" action="" onSubmit={ handleFormSubmit }>
          <div className="div-btn">
            <button onClick={ handleClick } className="btn-close" type="button">
              <span className="material-icons-outlined">close</span>
            </button>
          </div>
          <div className="container-colum-form">
            <div className="mini-container-form">
              <div className="ctn-input">
                <InputField
                  label="*ARTÍCULO"
                  name="item"
                  id="item"
                  value={ formState.item }
                  type="text"
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  className={ errors.item.error ? 'red-advertise' : '' }
                />
              </div>
              <div className="container-client-form container-form">
                {errors.item.dirty && errors.item.error ? (
                  <p className="error-validation">{errors.item.message}</p>
                ) : null}
              </div>
              <div className="select-input">
                <label className="label-select" htmlFor="as">
                  {' '}
                  <div className="text-label">*TIPO DE ARTÍCULO</div>
                  <select
                    // className="select-opt"
                    className={
                      errors.itemType.error
                        ? 'select-opt red-advertise'
                        : 'select-opt'
                    }
                    name="itemType"
                    id="itemType"
                    type="text"
                    value={ formState.itemType }
                    onClick={ () =>
                      setFormState({
                        ...formState,
                        nomenclatureId: '',
                        width: '',
                        height: '',
                        depth: '',
                      }) }
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                  >
                    <option className="option-select" value="">
                      Seleccione un tipo de artículo
                    </option>
                    {itemType.map((item) => (
                      <option
                        className="option-select"
                        key={ item.id }
                        value={ item.id }
                      >
                        {item.item_type}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="container-client-form container-form">
                {errors.itemType.dirty && errors.itemType.error ? (
                  <p className="error-validation">{errors.itemType.message}</p>
                ) : null}
              </div>
              <div className="select-input">
                <label className="label-select" htmlFor="as">
                  {' '}
                  <div className="text-label">*NOMENCLATURA</div>
                  <select
                    // className="select-opt"
                    className={
                      errors.nomenclatureId.error
                        ? 'select-opt red-advertise'
                        : 'select-opt'
                    }
                    name="nomenclatureId"
                    id="nomenclatureId"
                    type="text"
                    disabled={ formState.itemType === '' }
                    value={ formState.nomenclatureId }
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                  >
                    <option className="option-select" value="">
                      Seleccione una nomenclatura
                    </option>
                    {nomenclature.map((item) => {
                      if (String(item.item_type.id) === formState.itemType) {
                        return (
                          <option
                            className="option-select"
                            key={ item.id }
                            value={ item.id }
                          >
                            {item.nomenclature}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </label>
              </div>
              <div className="container-client-form container-form">
                {errors.nomenclatureId.dirty && errors.nomenclatureId.error ? (
                  <p className="error-validation">
                    {errors.nomenclatureId.message}
                  </p>
                ) : null}
              </div>
              {(formState.itemType === '1' &&
                formState.nomenclatureId !== '') ||
                (formState.itemType === '2' &&
                  formState.nomenclatureId !== '') ? (
                  <div className="ctn-input-sw">
                    <InputField
                      label="ALTO"
                      name="height"
                      value={ formState.height }
                      type="number"
                      onChange={ onInputChange }
                      min={ 0 }
                      isDecimal={
                        measurementunit.find(
                          (item) =>
                            String(item.id) === formState.measurementUnitId
                        )?.is_decimal
                      }
                    />
                    <InputField
                      label="ANCHO"
                      name="width"
                      value={ formState.width }
                      type="number"
                      onChange={ onInputChange }
                      min={ 0 }
                      isDecimal={
                        measurementunit.find(
                          (item) =>
                            String(item.id) === formState.measurementUnitId
                        )?.is_decimal
                      }
                    />
                    <InputField
                      label="ESPESOR"
                      name="depth"
                      value={ formState.depth }
                      type="number"
                      onChange={ onInputChange }
                      min={ 0 }
                      isDecimal={
                        measurementunit.find(
                          (item) =>
                            String(item.id) === formState.measurementUnitId
                        )?.is_decimal
                      }
                    />
                  </div>
                ) : null}
            </div>
            <div className="mini-container-form">
              <div className="select-input">
                <label className="label-select" htmlFor="as">
                  {' '}
                  <div className="text-label">*UNIDAD</div>
                  <select
                    // className="select-opt"
                    className={
                      errors.measurementUnitId.error
                        ? 'select-opt red-advertise'
                        : 'select-opt'
                    }
                    name="measurementUnitId"
                    id="measurementUnitId"
                    type="text"
                    value={ formState.measurementUnitId }
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                  >
                    <option className="option-select" default value="">
                      Seleccione una unidad de medida
                    </option>
                    {measurementunit?.map((item) => (
                      <option
                        className="option-select"
                        key={ item.id }
                        value={ item.id }
                      >
                        {item.measurement_unit}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="container-client-form container-form">
                {errors.measurementUnitId.dirty &&
                  errors.measurementUnitId.error ? (
                    <p className="error-validation">
                      {errors.measurementUnitId.message}
                    </p>
                  ) : null}
              </div>
              <div className="select-input">
                <label className="label-select" htmlFor="as">
                  {' '}
                  <div className="text-label">*MONEDA</div>
                  <select
                    // className="select-opt"
                    className={
                      errors.currencyId.error
                        ? 'select-opt red-advertise'
                        : 'select-opt'
                    }
                    name="currencyId"
                    id="currencyId"
                    type="text"
                    value={ formState.currencyId }
                    onBlur={ onBlurField }
                    onChange={ onInputChange }
                  >
                    <option className="option-select" default value="">
                      Seleccione un tipo de moneda
                    </option>
                    {currency.map((item) => (
                      <option
                        className="option-select"
                        key={ item.id }
                        value={ item.id }
                      >
                        {item.currency}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="container-client-form container-form">
                {errors.currencyId.dirty && errors.currencyId.error ? (
                  <p className="error-validation">
                    {errors.currencyId.message}
                  </p>
                ) : null}
              </div>
              <div className="ctn-input-sw">
                <InputField
                  label="*PRECIO"
                  name="price"
                  type="amount"
                  value={ formState.price }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  // className="input-pc"
                  className={
                    errors.price.error ? 'input-pc red-advertise' : 'input-pc'
                  }
                />
                <InputField
                  label="*COSTO"
                  name="cost"
                  type="amount"
                  value={ formState.cost }
                  onBlur={ onBlurField }
                  onChange={ onInputChange }
                  // className="input-pc"
                  className={
                    errors.cost.error ? 'input-pc red-advertise' : 'input-pc'
                  }
                />
              </div>
              <div className="container-client-form container-form">
                {(errors.price.dirty && errors.price.error) ||
                  (errors.cost.dirty && errors.cost.error) ? (
                    <p className="error-validation">
                      {errors.price.error
                        ? errors.price.message
                        : errors.cost.message}
                    </p>
                  ) : null}
              </div>
            </div>
          </div>
          <div className="ctn-input unit-textArea">
            <InputField
              label="DESCRIPCIÓN"
              name="description"
              type="text"
              value={ formState.description }
              onBlur={ onBlurField }
              onChange={ onInputChange }
            />
          </div>
          {/* <div className="container-client-form container-form">
            {errors.description.dirty && errors.description.error ? (
              <p className="error-validation">{errors.description.message}</p>
            ) : null}
          </div> */}
          <div className="container-check-articles">
            <label className="ctn-check" htmlFor="a">
              *HABILITADO
              <input
                id="enabled"
                name="enabled"
                className="inp-check"
                type="checkbox"
                defaultChecked={ formState.enabled }
                value={ !formState.enabled }
                onBlur={ onBlurField }
                onChange={ onInputChange }
              />
            </label>
          </div>
          <div className='buttonSaveContainer'>
            <button className="btn-save" type="submit">
              <span className="material-icons-outlined">save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default ArticlesForm;
