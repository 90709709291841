import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAuditComm,
  editsAuditComm,
  getAuditComms,
} from '../../../../data/redux/Clients/thunks';

import useForm from '../../../hooks/useForm';
import useCommValidator from '../../../hooks/useCommValidator';
import InputField from '../../InputField/InputField';

function ClientFormSpecialComm() {
  const { nomenclature, auditComm, loading, editClients } = useSelector(
    (state) => state.client
  );
  const { permissions } = useSelector((state) => state.auth);

  useEffect(() => {
    getAuditComms(editClients.id);
  }, []);

  const { formState, setFormState } = useForm({
    id: editClients ? editClients.id : '',
    commPercent:
      editClients && auditComm?.length === 0 ? 0 : auditComm?.[0]?.commission,
    approved:
      editClients && auditComm?.length === 0 ? 0 : auditComm?.[0]?.approved,
  });

  useEffect(() => {
    setFormState({
      id: editClients ? editClients.id : '',
      commPercent:
        editClients && auditComm?.length === 0 ? 0 : auditComm?.[0]?.commission,
      approved:
        editClients && auditComm?.length === 0 ? 0 : auditComm?.[0]?.approved,
    });
  }, [auditComm]);

  const { errors, validateForm, onBlurField } = useCommValidator(formState, 2);

  const dispatch = useDispatch();

  const bonusableComm = nomenclature.filter((item) => item.bonusable);

  const commissionsForm = () => {
    const comms = [];

    // Lista de nuevas comisiones (Las que no estaban antes)
    const newComms = bonusableComm.filter(
      (obj) => !auditComm.some((item) => item.nomenclature.id === obj.id)
    );

    newComms.map((comm) =>
      comms.push({
        id: null,
        nomenclature_id: comm.id,
        commission: Number(formState.commPercent),
      })
    );

    // Lista de comisiones actuales (Excepto las que se sacaron)
    const updateAuditComm = auditComm.filter((obj) =>
      bonusableComm.some((item) => obj.nomenclature.id === item.id)
    );

    updateAuditComm.map((comm) =>
      comms.push({
        id: comm.id,
        nomenclature_id: comm.nomenclature.id,
        commission: Number(formState.commPercent),
      })
    );
    return comms;
  };

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };

    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (
      (auditComm.length !== 0 && formState.approved === null) ||
      (auditComm.length !== 0 && formState.approved)
    )
      return toast.error(
        'No podrá agregar otra comisión especial hasta que el supervisor apruebe o rechace la misma'
      );

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const newForm = {
      commissions: commissionsForm(),
      client_id: editClients.id,
    };

    if (editClients) {
      dispatch(createAuditComm({
        _body: newForm
      }));
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    toast((t) => (
      <span>
        <p>Para reiniciar la comisión especial presione el botón Aceptar</p>
        <button
          type="button"
          onClick={ () => {
            const newForm = {
              reset: true,
            };
            dispatch(editsAuditComm({
              id: editClients.id, _body: newForm
            }));
            toast.dismiss(t.id);
          } }
        >
          Aceptar
        </button>
        <button type="button" onClick={ () => toast.dismiss(t.id) }>
          Cancelar
        </button>
      </span>
    ));
  };

  if (permissions.includes('client-update-commission')) {
    return !loading ? (
      <form className="form-base-special" action="">
        <div className="container-com-special">
          <div className="ctn-input">
            <InputField
              label="% COMISIÓN ESPECIAL"
              name="commPercent"
              type="number"
              id="commPercent"
              value={ formState.commPercent }
              onBlur={ onBlurField }
              onChange={ onInputChange }
              disabled={ Boolean(
                (auditComm?.length !== 0 && formState.approved === null) ||
                (auditComm?.length !== 0 && formState.approved)
              ) }
              className={ errors.commPercent.error ? 'red-advertise' : '' }
              min={ 0 }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.commPercent.dirty && errors.commPercent.error ? (
              <p className="error-validation">{errors.commPercent.message}</p>
            ) : null}
          </div>
        </div>
        {(auditComm?.length !== 0 && formState.approved === null) ||
          (auditComm?.length !== 0 && formState.approved) ? (
            <button
              className="btn-save"
              type="button"
              onClick={ (e) => handleReset(e) }
            >
              <span className="material-icons-outlined">restart_alt</span>
            </button>
          ) : (
            <button
              className="btn-save"
              type="button"
              onClick={ (e) => handleFormSubmit(e) }
            >
              <span className="material-icons-outlined">done</span>
            </button>
          )}
      </form>
    ) : null;
  }
}

export default ClientFormSpecialComm;
