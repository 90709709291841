import { useState } from 'react';
import {
  numberValidator,
  selectValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const useItemsValidator = (form) => {
  const [errors, setErrors] = useState({
    item: {
      dirty: false,
      error: false,
      message: '',
    },
    nomenclatureId: {
      dirty: false,
      error: false,
      message: '',
    },
    itemType: {
      dirty: false,
      error: false,
      message: '',
    },
    measurementUnitId: {
      dirty: false,
      error: false,
      message: '',
    },
    currencyId: {
      dirty: false,
      error: false,
      message: '',
    },
    cost: {
      dirty: false,
      error: false,
      message: '',
    },
    price: {
      dirty: false,
      error: false,
      message: '',
    },
    description: {
      dirty: false,
      error: false,
      message: '',
    },
    enabled: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const {
      item,
      nomenclatureId,
      itemType,
      measurementUnitId,
      currencyId,
      cost,
      price,
      // description,
      // enabled,
    } = form;

    if (nextErrors.item.dirty && (field ? field === 'item' : true)) {
      const itemMessage = textValidator(item);
      nextErrors.item.error = !!itemMessage;
      nextErrors.item.message = itemMessage;
      if (itemMessage) isValid = false;
    }
    if (nextErrors.itemType.dirty && (field ? field === 'itemType' : true)) {
      const itemTypeMessage = selectValidator(itemType);
      nextErrors.itemType.error = !!itemTypeMessage;
      nextErrors.itemType.message = itemTypeMessage;
      if (itemTypeMessage) isValid = false;
    }
    if (
      nextErrors.nomenclatureId.dirty &&
      (field ? field === 'nomenclatureId' : true)
    ) {
      const nomenclatureIdMessage = selectValidator(nomenclatureId);
      nextErrors.nomenclatureId.error = !!nomenclatureIdMessage;
      nextErrors.nomenclatureId.message = nomenclatureIdMessage;
      if (nomenclatureIdMessage) isValid = false;
    }
    if (
      nextErrors.measurementUnitId.dirty &&
      (field ? field === 'measurementUnitId' : true)
    ) {
      const measurementUnitIdMessage = selectValidator(measurementUnitId);
      nextErrors.measurementUnitId.error = !!measurementUnitIdMessage;
      nextErrors.measurementUnitId.message = measurementUnitIdMessage;
      if (measurementUnitIdMessage) isValid = false;
    }

    if (
      nextErrors.currencyId.dirty &&
      (field ? field === 'currencyId' : true)
    ) {
      const currencyIdMessage = selectValidator(currencyId);
      nextErrors.currencyId.error = !!currencyIdMessage;
      nextErrors.currencyId.message = currencyIdMessage;
      if (currencyIdMessage) isValid = false;
    }
    if (nextErrors.cost.dirty && (field ? field === 'cost' : true)) {
      const costMessage = numberValidator(cost);
      nextErrors.cost.error = !!costMessage;
      nextErrors.cost.message = costMessage;
      if (costMessage) isValid = false;
    }
    if (nextErrors.price.dirty && (field ? field === 'price' : true)) {
      const priceMessage = numberValidator(price);
      nextErrors.price.error = !!priceMessage;
      nextErrors.price.message = priceMessage;
      if (priceMessage) isValid = false;
    }
    // if (nextErrors.description.dirty && (field ? field === 'text' : true)) {
    //   const descriptionMessage = textValidator(description)
    //   nextErrors.description.error = !!descriptionMessage
    //   nextErrors.description.message = descriptionMessage
    //   if (descriptionMessage) isValid = false
    // }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({
      form, field, errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField,
  };
};

export default useItemsValidator;
