import React from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../../hooks/useForm';
import useCommValidator from '../../../hooks/useCommValidator';
import { editsClientComm } from '../../../../data/redux/Clients/thunks';
import InputField from '../../InputField/InputField';

function ClientFormComm() {
  const { nomenclature, clientComm, loading, editClients } = useSelector(
    (state) => state.client
  );
  const { permissions } = useSelector((state) => state.auth);

  const { formState, setFormState } = useForm({
    id: editClients ? editClients.id : '',
    commPercent: clientComm?.length === 0 ? 0 : clientComm?.[0]?.commission,
  });

  const { errors, validateForm, onBlurField } = useCommValidator(formState, 1);

  const dispatch = useDispatch();

  const bonusableComm = nomenclature.filter((item) => item.bonusable);

  const commissionsForm = () => {
    const comms = [];

    // Lista de nuevas comisiones (Las que no estaban antes)
    const newComms = bonusableComm.filter(
      (obj) => !clientComm.some((item) => item.nomenclature.id === obj.id)
    );

    newComms.map((comm) =>
      comms.push({
        id: null,
        nomenclature_id: comm.id,
        commission: Number(formState.commPercent),
      })
    );

    // Lista de comisiones actuales (Excepto las que se sacaron)
    const updateClientComm = clientComm.filter((obj) =>
      bonusableComm.some((item) => obj.nomenclature.id === item.id)
    );

    updateClientComm.map((comm) =>
      comms.push({
        id: comm.id,
        nomenclature_id: comm.nomenclature.id,
        commission: Number(formState.commPercent),
      })
    );
    return comms;
  };

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value,
    };

    setFormState({
      ...formState,
      [name]: value,
    });

    if (errors[name].dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true,
    });
    if (!isValid) return toast.error('Los datos ingresados no son válidos');

    const newForm = {
      commissions: commissionsForm(),
      client_id: editClients.id,
    };

    if (editClients) {
      dispatch(editsClientComm({
        id: formState.id, _body: newForm
      }));
    }
  };

  if (permissions.includes('client-update-commission')) {
    return !loading ? (
      <form className="comision-cliente" action="">
        <div className="container-com-special">
          <div className="ctn-input">
            <InputField
              label="% COMISIÓN"
              name="commPercent"
              type="number"
              id="commPercent"
              value={ formState.commPercent }
              onBlur={ onBlurField }
              onChange={ onInputChange }
              className={ errors.commPercent.error ? 'red-advertise' : '' }
              min={ 0 }
            />
          </div>
          <div className="container-client-form container-form">
            {errors.commPercent.dirty && errors.commPercent.error ? (
              <p className="error-validation">{errors.commPercent.message}</p>
            ) : null}
          </div>
        </div>
        <div className='buttonSaveContainer'>
          <button className="btn-save" type="button" onClick={ handleFormSubmit }>
            <span className="material-icons-outlined">done</span>
          </button>
        </div>
      </form>
    ) : null;
  }
}

export default ClientFormComm;
