/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getUsers, { getRoles } from './thunks';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: [],
    roles: [],
    editUser: null,
    userComm: null,
    loading: true,
    nomenclature: [],
  },

  reducers: {
    addUsers: (state, { payload }) => {
      state.user = [...state.user, {
        ...payload
      }];
      state.editUser = null;
    },

    editUsers: (state, { payload }) => {
      state.user = state.user.map((u) => (u.id === payload.id ? payload : u));
      state.editUser = null;
      state.userComm = null;
    },

    deleteUsers: (state, { payload }) => {
      state.user = state.user.filter((u) => u.id !== payload);
      state.loading = false;
    },

    selectUsers: (state, { payload }) => {
      state.editUser = state.user.find((u) => u.id === payload.id);
    },

    onLoadingUsers: (state) => {
      state.loading = true;
    },

    editUser: (state, { payload }) => {
      state.user = payload;
    },
    selectNomenclatures: (state, { payload }) => {
      state.nomenclature = payload.unit_types;
      state.loading = false;
    },
    selectUserComms: (state, { payload }) => {
      state.userComm = payload;
      state.loading = false;
    },
    editUserComm: (state, { payload }) => {
      state.userComm = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.editUser = null;
      state.userComm = null;
      state.loading = false;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.user = 'fail';
      state.editUser = null;
      state.userComm = null;
    });
    builder.addCase(getRoles.fulfilled, (state, { payload }) => {
      state.roles = payload;
      state.editUser = null;
      state.userComm = null;
      state.loading = false;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.roles = 'fail';
      state.editUser = null;
      state.userComm = null;
    });
  },
});

export const {
  addUsers,
  editUsers,
  deleteUsers,
  selectUsers,
  onLoadingUsers,
  editUser,
  selectNomenclatures,
  selectUserComms,
  editUserComm,
  setLoading,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
