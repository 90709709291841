/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getClient from './thunks';

export const orderStatusesSlice = createSlice({
  name: 'ordersStatuses',
  initialState: {
    editOrders: null,
    loading: true,
    ordersStatuses: [],
  },

  reducers: {
    selectOrderStatuses: (state, { payload }) => {
      state.ordersStatuses = payload;
      state.loading = false;
    },
    changeLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClient.fulfilled, (state, { payload }) => {
      state.ordersStatuses = payload;
      state.editOrders = null;
      state.loading = false;
    });
    builder.addCase(getClient.rejected, (state) => {
      state.ordersStatuses = 'fail';
      state.editOrders = null;
    });
  },
});

export const { selectOrderStatuses, changeLoading } = orderStatusesSlice.actions;

export const orderStatusesReducer = orderStatusesSlice.reducer;
