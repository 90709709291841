import ButtonClientsModMobile from '../components/ButtonClientsModMobile';
import { CLIENT_FINAL_ID } from '../../../../helpers/rolesConstants';

export const NO_ORDERS_MSG = 'S/ pedidos';

const columnsTableMobile = [
  {
    name: 'N°',
    selector: (row) => row?.id,
    sortable: true,
    minWidth: '3%',
    sortField: 'id'
  },
  {
    name: 'CLIENTE',
    selector: (row) =>
      row?.client_type?.id === CLIENT_FINAL_ID
        ? `${row?.fullname}`
        : `${row?.company} | ${row?.fullname}`,
    sortable: true,
    minWidth: '40%',
    sortField: 'fullname'
  },
  {
    name: 'U$D',
    selector: (row) =>
      `${row?.total_dollar_balance
        ? `U$D ${row?.total_dollar_balance}`
        : NO_ORDERS_MSG 
      }`,
    sortable: true,
    minWidth: '7%',
    sortField: 'total_dollar_balance'
  },
  {
    name: 'AR$',
    selector: (row) =>
      `${row?.total_peso_balance
        ? `$ ${row?.total_peso_balance}`
        : NO_ORDERS_MSG 
      }`,
    sortable: true,
    minWidth: '7%',
    sortField: 'total_peso_balance'
  },
  {
    name: '',
    cell: (row) => ButtonClientsModMobile(row),
    sortable: false,
    button: true,
    minWidth: '10%',
  },
];

export default columnsTableMobile;
