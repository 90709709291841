import React from 'react';
import PropTypes from 'prop-types';

function DetailsTableDesktop({ data }) {
  return (
    <div className="container-detail-main">
      <div className="detail-data">
        <div>
          <p className="p-title-detail">CUIT:</p>
          <p className="p-Details">{data?.CUIT}</p>
        </div>
        <div>
          <p className="p-title-detail">DIRECCIÓN:</p>
          <p className="p-Details">{data?.address}</p>
        </div>
        <div>
          <p className="p-title-detail">TEL:</p>
          <p className="p-Details">{data?.phone}</p>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

DetailsTableDesktop.propTypes = {
  data: PropTypes.shape({
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    commissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        commission: PropTypes.number.isRequired,
      })
    ),
    fullName: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    CUIT: PropTypes.string,
    enabled: PropTypes.number,
  }).isRequired,
};

export default DetailsTableDesktop;
