/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getItem from './thunks';

export const itemSlice = createSlice({
  name: 'item',
  initialState: {
    item: [],
    editItems: null,
    loading: true,
    nomenclature: [],
    currency: [],
    measurementunit: [],
    itemType: [],
  },

  reducers: {
    addItem: (state, { payload }) => {
      state.item = [...state.item, {
        ...payload
      }];
      state.editItems = null;
    },

    // loadMassiveItem: (state, { payload }) => {
    loadMassiveItem: (state) => {
      // state.item = [...state.item, { ...payload }]
      state.loading = false;
    },

    editItem: (state, { payload }) => {
      state.item = state.item.map((item) =>
        item.id === payload.id ? payload : item
      );
      state.editItems = null;
    },

    deleteItem: (state, { payload }) => {
      state.item = state.item.filter((item) => item.id !== payload);
      state.loading = false;
    },

    selectItem: (state, { payload }) => {
      state.editItems = state.item.find((item) => item.id === payload.id);
    },

    onLoadingItem: (state) => {
      state.loading = true;
    },

    editItems: (state, { payload }) => {
      state.item = payload;
    },

    selectNomenclatures: (state, { payload }) => {
      state.nomenclature = payload.unit_types;
      state.loading = false;
    },
    selectItemTypes: (state, { payload }) => {
      state.itemType = payload.itemTypes;
      state.loading = false;
    },
    selectCurrencies: (state, { payload }) => {
      state.currency = payload.currencies;
      state.loading = false;
    },
    selectMUnits: (state, { payload }) => {
      state.measurementunit = payload;
      state.loading = false;
    },
    uploadMassiveItem: (state) => {
      state.loading = false;
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItem.fulfilled, (state, { payload }) => {
      state.item = payload;
      state.editItems = null;
      state.loading = false;
    });
    builder.addCase(getItem.rejected, (state) => {
      state.item = 'fail';
      state.editItems = null;
    });
  },
});

export const {
  addItem,
  editItem,
  deleteItem,
  selectItem,
  onLoadingItem,
  editItems,
  selectNomenclatures,
  selectItemTypes,
  selectCurrencies,
  selectMUnits,
  loadMassiveItem,
  uploadMassiveItem,
  setLoading,
} = itemSlice.actions;

export const itemReducer = itemSlice.reducer;
