import React from 'react';
import HomeComponent from '../components/home/HomeComponent';
// import { useDispatch, useSelector } from 'react-redux'
// import { logout } from '../../data/redux/auth/authSlice'

function Home() {
  // const dispatch = useDispatch()
  // const { displayName } = useSelector((state) => state.user)

  // Esta funcion solo se creo para comprobar el logout debe borrarse
  // const Logout = () => {
  //   dispatch(logout())
  // }

  return (
    <div className="container">
      <HomeComponent />
    </div>
  );
}

export default Home;
