import React from 'react';
import PropTypes from 'prop-types';

function Modal({
  clickedImg,
  handleRotationRight,
  handelRotationLeft,
  setActModal,
}) {
  const handleClick = (e) => {
    if (e.target.classList.contains('dismiss')) {
      setActModal(false);
    }
  };

  return (
    <div className="overlay dismiss">
      <button
        className="container-main-modal-img"
        type="button"
        onClick={ handleClick }
      >
        <button type="button" className="btn-close" onClick={ handleClick }>
          <span className="dismiss">X</span>
        </button>
        <img
          src={
            !clickedImg.name
              ? `${process.env.REACT_APP_API_BACKEND}${clickedImg}`
              : URL.createObjectURL(clickedImg)
          }
          alt="bigger_picture"
        />
        <div className="overlay-arrows_left">
          <button type="button" onClick={ handelRotationLeft }>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="overlay-arrows_right">
          <button type="button" onClick={ handleRotationRight }>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </button>
    </div>
  );
}

Modal.propTypes = {
  clickedImg: PropTypes.string.isRequired,
  handleRotationRight: PropTypes.func.isRequired,
  handelRotationLeft: PropTypes.func.isRequired,
  setActModal: PropTypes.func.isRequired,
};

export default Modal;
