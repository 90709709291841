import ButtonUnitsMod from '../components/ButtonUnitsMod';
import { nomenclatureCaseInsensitiveSort, measurementUnitCaseInsensitiveSort } from './columnsTableDesktop';

const columnsTable = [
  {
    name: 'UNIDAD',
    selector: (row) => row?.measurement_unit,
    sortable: true,
    minWidth: '40%',
    sortField: 'measurement_unit',
    sortFunction: measurementUnitCaseInsensitiveSort 
  },
  {
    name: 'NOMENCLATURA',
    selector: (row) => row?.nomenclature,
    sortable: true,
    minWidth: '30%',
    sortField: 'nomenclature',
    sortFunction: nomenclatureCaseInsensitiveSort 
  },
  {
    name: '',
    cell: (row) => ButtonUnitsMod(row),
    sortable: true,
    button: true,
    minWidth: '15%',
  },
];

export default columnsTable;
