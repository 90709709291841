import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import InputField from '../InputField/InputField';
import { reportsOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import {
  getClients,
  getCurrencies,
  getItems,
  getOrderStatus,
  getPaymentStatus,
  getReport,
  getUnits,
  getUsers,
} from '../../../data/redux/Reports/thunks';
import Spinner from '../Spinner/Spinner';
import { editFilterFields } from '../../../data/redux/Reports/reportSlice';

function Reports() {
  const dispatch = useDispatch();

  const {
    loading,
    paymentStatuses,
    orderStatuses,
    currencies,
    measurementunit,
    items,
    clients,
    users,
  } = useSelector((state) => state.report);

  const { formState, setFormState, onInputChange } = useForm({
    fromDate: '',
    period: '',
    toDate: '',
    comparison: false,
    userId: '',
    clientId: '',
    isOpen: '',
    orderStatusId: '',
    paymentStatusId: '',
    itemId: '',
    currencyId: '',
    unitId: '',
  });

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    paymentStatuses?.length === 0 && dispatch(getPaymentStatus());
    // eslint-disable-next-line no-unused-expressions
    orderStatuses?.length === 0 && dispatch(getOrderStatus());
    // eslint-disable-next-line no-unused-expressions
    currencies?.length === 0 && dispatch(getCurrencies());
    dispatch(getUnits());
    dispatch(getItems());
    dispatch(getClients());
    dispatch(getUsers());
    dispatch(editFilterFields(formState));
  }, []);

  const handleSelectToDate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({
      ...formState, [name]: value, period: ''
    });
  };

  const handleSelectPeriod = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({
      ...formState, [name]: value, toDate: ''
    });
  };

  const handleSearch = () => {
    const newForm = {
      fromDate: formState.fromDate,
      period: Number(formState.period) ? Number(formState.period) : null,
      toDate: formState.toDate ? formState.toDate : null,
      comparison: formState.comparison,
      userId: Number(formState.userId) ? Number(formState.userId) : null,
      clientId: Number(formState.clientId) ? Number(formState.clientId) : null,
      isOpen:
        formState.isOpen === '' ? null : Boolean(Number(formState.isOpen)),
      orderStatusId: Number(formState.orderStatusId)
        ? Number(formState.orderStatusId)
        : null,
      paymentStatusId: Number(formState.paymentStatusId)
        ? Number(formState.paymentStatusId)
        : null,
      itemId: Number(formState.itemId) ? Number(formState.itemId) : null,
      currencyId: Number(formState.currencyId)
        ? Number(formState.currencyId)
        : null,
      unitId: Number(formState.unitId) ? Number(formState.unitId) : null,
    };
    dispatch(getReport(newForm));
  };

  return !loading ? (
    <div>
      <NavSec title="REPORTES" content={ reportsOptions } />
      <div className="container-reports-main">
        <div className="reports-input">
          <InputField
            label="* DESDE FECHA"
            name="fromDate"
            id="fromDate"
            value={ formState.fromDate }
            type="date"
            onChange={ onInputChange }
          />
        </div>
        <div className="reports-input">
          <InputField
            label="* HASTA LA FECHA"
            name="toDate"
            id="toDate"
            value={ formState.toDate }
            type="date"
            onChange={ handleSelectToDate }
          />
        </div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">PERÍODO</div>
            <select
              className="select-opt"
              name="period"
              id="period"
              onChange={ handleSelectPeriod }
              value={ formState.period }
            >
              <option className="option-select" default value="option">
                Seleccione un Periodo
              </option>
              <option className="option-select" value="1">
                Diario
              </option>
              <option className="option-select" value="2">
                Semanal
              </option>
              <option className="option-select" value="3">
                Mensual
              </option>
              <option className="option-select" value="4">
                Anual
              </option>
            </select>
          </label>
        </div>
        <div className="line-red">.</div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">USUARIO</div>
            <select
              className="select-opt"
              name="userId"
              id="userId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="">
                Seleccione un Usuario
              </option>
              {users.map((user) => (
                <option key={ user.id } value={ user.id }>
                  {user.fullName}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">CLIENTE</div>
            <select
              className="select-opt"
              name="clientId"
              id="clientId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="option">
                Seleccione un Cliente
              </option>
              {clients.map((client) => (
                <option key={ client.id } value={ client.id }>
                  {`[${client.id}] ${client.fullname} / ${client.client_type.client_type}`}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="line-red">.</div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">ARTÍCULOS</div>
            <select
              className="select-opt"
              name="itemId"
              id="itemId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="option">
                Seleccione un Articulo
              </option>
              {items?.map((item) => (
                <option key={ item.id } value={ item.id }>
                  {`${item.nomenclature.nomenclature}-${item.id} | ${item.item}`}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">MONEDA</div>
            <select
              className="select-opt"
              name="currencyId"
              id="currencyId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="option">
                Seleccione una Moneda
              </option>
              {currencies.map((currency) => (
                <option key={ currency.id } value={ currency.id }>
                  {currency.currency}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">UNIDADES DE MEDIDA</div>
            <select
              className="select-opt"
              name="unitId"
              id="unitId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="option">
                Seleccione una Unidad de Medida
              </option>
              {measurementunit?.map((unit) => (
                <option key={ unit.id } value={ unit.id }>
                  {`${unit.nomenclature} (${unit.measurement_unit})`}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="line-red">.</div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">TIPO DE PRESUPUESTO</div>
            <select
              className="select-opt"
              name="isOpen"
              id="isOpen"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="">
                Seleccione un Tipo de Presupuesto
              </option>
              <option className="option-select" value={ 0 }>
                Cerrado
              </option>
              <option className="option-select" value={ 1 }>
                Abierto
              </option>
            </select>
          </label>
        </div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">ESTADO DE PEDIDO</div>
            <select
              className="select-opt"
              name="orderStatusId"
              id="orderStatusId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="option">
                Seleccione un Estado de Pedido
              </option>
              {orderStatuses.map((orderStatus) => (
                <option key={ orderStatus.id } value={ orderStatus.id }>
                  {orderStatus.order_status}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="reports-input-select">
          <label className="report-label-select" htmlFor="as">
            {' '}
            <div className="text-label">ESTADO DE PAGO</div>
            <select
              className="select-opt"
              name="paymentStatusId"
              id="paymentStatusId"
              onChange={ onInputChange }
            >
              <option className="option-select" default value="option">
                Seleccione un Estado de Pago
              </option>
              {paymentStatuses.map((paymentStatus) => (
                <option key={ paymentStatus.id } value={ paymentStatus.id }>
                  {paymentStatus.payment_status}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="reports-input-select">
          <label className="ctn-check" htmlFor="as">
            {' '}
            <div className="text-label">COMPARAR CON AÑO ANTERIOR</div>
            <input
              type="checkbox"
              id="comparison"
              name="comparison"
              className="inp-check"
              onChange={ onInputChange }
            />
          </label>
        </div>
      </div>
      <div className="container-btn-reports">
        <button className="btn-Main" type="button" onClick={ handleSearch }>
          <Link to="Generated">
            <span className="material-icons-outlined">manage_search</span>
          </Link>
        </button>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default Reports;
