import React from 'react';
import { Link } from 'react-router-dom';
import { reportsOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';

function ReportFinal() {
  const filteredData = {
    from: '01/01/22',
    date: '11/07/22',
    period: '',
    last_year: '',
    measurement_unit: '',
    articles: 'Bacha',
    user: '',
    to: '01/11/22',
    client: 'Felico',
    currency: 'US$',
    sales: 3568.5,
    state_budget: '',
    states: 'Pagado',
    state_payment: '',
    total: 9603.09,
  };

  return (
    <div>
      <NavSec title="REPORTES" content={ reportsOptions } />
      <div className="container-btn">
        <div className="container-btn-report">
          <button className="btn-close" type="button">
            <Link to="/reports/Generated">
              <span className="material-icons-outlined">close</span>
            </Link>
          </button>
        </div>
      </div>
      <div className="container-report-formTo">
        <div className="container-date">
          <h3>Fecha desde:</h3>
          <p>{filteredData.from}</p>
        </div>
        <div className="container-date">
          <h3>Fecha hasta:</h3>
          <p>{filteredData.to}</p>
        </div>
      </div>
      <div className="container-title-report-final">
        <div className="title-report-final">
          <p>{filteredData.client}</p>
          <p>
            {filteredData.currency} {filteredData.sales}
          </p>
        </div>
      </div>
      <div className="card-report-final">
        <div className="card-report-data">
          <h3>Fecha:</h3>
          <p>{filteredData.date}</p>
        </div>
        <div className="card-report-data">
          <h3>Moneda:</h3>
          <p>{filteredData.currency}</p>
        </div>
        <div className="card-report-data">
          <h3>Estado:</h3>
          <p>{filteredData.states}</p>
        </div>
        <div className="card-report-data">
          <h3>Artículos:</h3>
          <p>{filteredData.articles}</p>
        </div>
      </div>
      <div className="container-title-report-final">
        <div className="title-report-final">
          <p>{filteredData.client}</p>
          <p>
            {filteredData.currency} {filteredData.sales}
          </p>
        </div>
      </div>
      <div className="card-report-final">
        <div className="card-report-data">
          <h3>Fecha:</h3>
          <p>{filteredData.date}</p>
        </div>
        <div className="card-report-data">
          <h3>Moneda:</h3>
          <p>{filteredData.currency}</p>
        </div>
        <div className="card-report-data">
          <h3>Estado:</h3>
          <p>{filteredData.states}</p>
        </div>
        <div className="card-report-data">
          <h3>Artículos:</h3>
          <p>{filteredData.articles}</p>
        </div>
      </div>
      <div className="container-title-report-final">
        <div className="title-report-final">
          <p>{filteredData.client}</p>
          <p>
            {filteredData.currency} {filteredData.sales}
          </p>
        </div>
      </div>
      <div className="card-report-final">
        <div className="card-report-data">
          <h3>Fecha:</h3>
          <p>{filteredData.date}</p>
        </div>
        <div className="card-report-data">
          <h3>Moneda:</h3>
          <p>{filteredData.currency}</p>
        </div>
        <div className="card-report-data">
          <h3>Estado:</h3>
          <p>{filteredData.states}</p>
        </div>
        <div className="card-report-data">
          <h3>Artículos:</h3>
          <p>{filteredData.articles}</p>
        </div>
      </div>
      <div className="total-report">
        <h3>Total</h3>
        <p>
          {filteredData.currency} {filteredData.total}
        </p>
      </div>
      <div className="container-btn-reports">
        <button className="btn-Main" type="button">
          <Link to="/report/final">
            <span className="material-icons-outlined">save_alt</span>
          </Link>
        </button>
        <button className="btn-Main" type="button">
          <Link to="/Reports">
            <span className="material-icons-outlined">arrow_back_ios</span>
          </Link>
        </button>
      </div>
    </div>
  );
}

export default ReportFinal;
