import React from 'react';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';
import columnsTableMobile from './Tables/columnsTableMobile';
import DetailsTableMobile from './components/DetailsTableMobile';

const conditionalStyles = [
  {
    when: (row) => !row.enabled,
    style: {
      color: '#c5b6b6',
    },
  },
];

function UserMobile() {
  const { user } = useSelector((state) => state.user);
  return (
    <Table
      className="marmoleria--datatable user-datatable"
      selectRows={ false }
      columnsTable={ columnsTableMobile }
      filteredData={ user }
      detailsTable={ DetailsTableMobile }
      filterEnabled
      conceptsFilter={ [
        'fullName',
        'CUIT',
        'email',
        'address',
        'phone',
        'roles.name',
        'commissions.commissions',
      ] }
      conditionalStyles={ conditionalStyles }
      messageNoData={ <h1 className="no-data-message">NO SE ENCONTRARON USUARIOS</h1> }
      section="user"
      lastPageByFilteredData
    />
  );
}

export default UserMobile;
