import { rolesOptions } from '../../../../helpers/dataOptions';
import ButtonUserMod from '../components/ButtonUserMod';

export const SORT_ORDER = {
  BEFORE: -1,
  EQUAL: 0,
  AFTER: 1
};

export const caseInsensitiveSort = (rowA, rowB) => {
  const fullNameA = rowA.fullName.toLowerCase();
  const fullNameB = rowB.fullName.toLowerCase();

  if (fullNameA < fullNameB) return SORT_ORDER.BEFORE;
  if (fullNameA > fullNameB) return SORT_ORDER.AFTER;
  return SORT_ORDER.EQUAL;
};

const columnsTableDesktop = [
  {
    name: 'NOMBRE',
    selector: (row) => row?.fullName ?? '',
    sortable: true,
    minWidth: '20%',
    sortFunction: caseInsensitiveSort
  },
  {
    name: 'EMAIL',
    selector: (row) => row?.email,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'ROL',
    selector: (row) => {
      const roleName = row?.roles?.[0]?.name;
      const roleOption = rolesOptions.find((option) => option.name === roleName);
      const displayName = roleOption ? roleOption.displayName : '';
      return displayName;
    },
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'COMISIÓN',
    selector: (row) =>
      row?.commissions?.length ? `${row?.commissions?.[0]?.commission}%` : '0%',
    sortable: true,
    minWidth: '20%',
  },
  {
    name: '',
    cell: (row) => ButtonUserMod(row),
    sortable: true,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
