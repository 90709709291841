/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
// import getOrders from './thunks'

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    loading: true,
    searchLoading: false,
    paymentStatuses: [],
    orderStatuses: [],
    currencies: [],
    measurementunit: [],
    items: [],
    clients: [],
    users: [],
    reportOrders: [],
    filterFields: {
      fromDate: '',
      period: '',
      toDate: '',
      comparison: false,
      userId: '',
      rolId: '',
      clientId: '',
      clientTypeId: '',
      isOpen: '',
      commPaid: '',
      orderStatusId: '',
      paymentStatusId: '',
      itemId: '',
      currencyId: '',
      unitId: '',
      nameReport: '',
    },
  },

  reducers: {
    onLoadingOrder: (state) => {
      state.loading = true;
    },
    onLoadingSearch: (state) => {
      state.searchLoading = true;
    },
    editReport: (state, { payload }) => {
      state.reportOrders = payload;
      state.loading = false;
      state.searchLoading = false;
    },
    editFilterFields: (state, { payload }) => {
      state.filterFields = payload;
    },
    selectPaymentStatus: (state, { payload }) => {
      state.paymentStatuses = payload.payment_statuses;
      state.loading = false;
    },
    selectOrderStatus: (state, { payload }) => {
      state.orderStatuses = payload.order_statuses;
      state.loading = false;
    },
    selectCurrencies: (state, { payload }) => {
      state.currencies = payload.currencies;
      state.loading = false;
    },
    selectUnits: (state, { payload }) => {
      state.measurementunit = payload.original;
      state.loading = false;
    },
    selectItems: (state, { payload }) => {
      state.items = payload.original;
      state.loading = false;
    },
    selectClients: (state, { payload }) => {
      state.clients = payload.clients;
      state.loading = false;
    },
    selectUsers: (state, { payload }) => {
      state.users = payload.data;
      state.loading = false;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(getOrders.fulfilled, (state, { payload }) => {
  //     state.order = payload
  //     state.filterFields = null
  //     state.reportOrders = null
  //     state.clients = []
  //     state.sectors = []
  //     state.items = []
  //     state.loading = false
  //   })
  //   builder.addCase(getOrders.rejected, (state) => {
  //     state.order = 'fail'
  //     state.reportOrders = null
  //   })
  // },
});

export const {
  onLoadingOrder,
  onLoadingSearch,
  selectPaymentStatus,
  selectOrderStatus,
  selectCurrencies,
  selectUnits,
  selectItems,
  selectClients,
  selectUsers,
  editReport,
  editFilterFields,
} = reportSlice.actions;

export const reportReducer = reportSlice.reducer;
