import React from 'react';
import PropTypes from 'prop-types';

function DetailsTableMobile({ data }) {
  return (
    <div className="container-units">
      <div className="container-detail-units">
        <div className="detail-data detail-units">
          <h3 className="h-detail-units">TIPO DE UNIDAD:</h3>
          <p className="units-d">{data?.unit_type?.unit_type}</p>
          <h3 className="h-detail-units">DESCRIPCIÓN:</h3>
          <p className="units-d">
            {data?.description ? data?.description : 'No tiene descripción'}
          </p>
          <div className="units-input-detail">
            <label htmlFor="a">
              ENTERO:
              <input
                className="inp-units"
                type="checkbox"
                defaultChecked={ !data?.is_decimal }
                readOnly
                disabled
              />
            </label>
            <label htmlFor="a">
              DECIMAL:
              <input
                className="inp-units"
                type="checkbox"
                defaultChecked={ data?.is_decimal }
                readOnly
                disabled
              />
            </label>
          </div>
        </div>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

DetailsTableMobile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    measurement_unit: PropTypes.string,
    is_decimal: PropTypes.bool,
    unit_type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      unit_type: PropTypes.string,
    }),
  }).isRequired,
};
export default DetailsTableMobile;
