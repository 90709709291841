import ButtonArticleMod from '../components/ButtonItemsMod';
import { SORT_ORDER } from '../../User/Tables/columnsTableDesktop';

export const caseInsensitiveSort = (rowA, rowB) => {
  const itemA = rowA.item.toLowerCase();
  const itemB = rowB.item.toLowerCase();

  if (itemA < itemB) return SORT_ORDER.BEFORE;
  if (itemA > itemB) return SORT_ORDER.AFTER;
  return SORT_ORDER.EQUAL;
};

export const numberSort = (rowA, rowB) => {
  const itemA = rowA?.price;
  const itemB = rowB?.price;

  if (itemA < itemB) return SORT_ORDER.BEFORE;
  if (itemA > itemB) return SORT_ORDER.AFTER;
  return SORT_ORDER.EQUAL;
};

const columnsTableDesktop = [
  {
    name: 'CÓDIGO',
    selector: (row) => `${row?.nomenclature?.nomenclature}-${row?.id}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: 'ARTÍCULOS',
    selector: (row) => row?.item,
    sortable: true,
    minWidth: '20%',
    sortFunction: caseInsensitiveSort,
  },
  {
    name: 'PRECIO',
    selector: (row) => `${row?.currency?.currency} ${row?.price}`,
    sortable: true,
    minWidth: '20%',
    sortFunction: numberSort,
  },
  {
    name: 'ESTADO',
    selector: (row) => `${row?.enabled ? 'Habilitado' : 'Deshabilitado'}`,
    sortable: true,
    minWidth: '20%',
  },
  {
    name: '',
    cell: (row) => ButtonArticleMod(row),
    sortable: true,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
