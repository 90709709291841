import { useState } from 'react';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { closeOrder } from '../../../../data/redux/Orders/thunks';
import { editOrder } from '../../../../data/redux/Orders/orderSlice';

function useQuotes() {
  const [quotes, setQuotes] = useState([]);
  const dispatch = useDispatch();

  const handleConfirmCloseOrder = async (quote, quotesEdit) => {
    try {
      const updatedQuotes = await closeOrder(quote.id);
      const quoteIsEdit = quotesEdit.find((or) => or.id === updatedQuotes.id);

      if (quoteIsEdit) {
        updatedQuotes.client = quoteIsEdit.client;
        updatedQuotes.user = quoteIsEdit.user;
        updatedQuotes.order_status = quoteIsEdit.order_status;
        updatedQuotes.sectors = quoteIsEdit.sectors;
      }

      dispatch(editOrder(updatedQuotes));
      setQuotes(updatedQuotes);
    } catch (error) {
      toast.error(error);
    }
  };

  return {
    quotes, handleConfirmCloseOrder
  };
}
export default useQuotes;
