/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: 'no-authenticated',
    uid: null,
    email: null,
    displayName: null,
    message: null,
    token: null,
    roles: null,
    permissions: null,
    user_color: null,
    loading: false,
  },

  reducers: {
    checking: (state) => {
      state.status = 'checking';
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },

    errorLogin: (state, { payload }) => {
      state.status = 'no-authenticated';
      state.message = payload.message;
    },

    errorLogout: (state, { payload }) => {
      state.message = payload.message;
    },

    changePassword: (state, { payload }) => {
      state.message = payload.message;
    },

    errorChangePassword: (state, { payload }) => {
      state.message = payload.message;
    },

    recoveryPassword: (state, { payload }) => {
      state.message = payload.message;
    },

    errorRecoveryPassword: (state, { payload }) => {
      state.message = payload.message;
    },

    resetPassword: (state, { payload }) => {
      state.message = payload.message;
    },

    errorResetPassword: (state, { payload }) => {
      state.message = payload.message;
    },

    login: (state, { payload }) => {
      state.status = 'authenticated';
      state.uid = payload.data.id;
      state.email = payload.data.email;
      state.displayName = payload.data.fullName;
      state.message = payload.message;
      state.token = payload.data.auth_token;
      state.roles = payload.data.roles;
      state.permissions = payload.data.permissions;
      state.commissions = payload.data.commissions;
      state.user_color = payload.data.user_color;

      const authState = {
        status: state.status,
        uid: state.uid,
        email: state.email,
        displayName: state.displayName,
        message: state.message,
        token: state.token,
        roles: state.roles,
        permissions: state.permissions,
        commissions: state.commissions,
        userColor: state.user_color,
      };

      localStorage.setItem('persist:auth', JSON.stringify(authState));
    },

    logout: (state) => {
      localStorage.clear();

      state.status = 'no-authenticated';
      state.uid = null;
      state.email = null;
      state.displayName = null;
      state.photoURL = null;
      state.message = null;
      state.token = null;
      state.roles = null;
      state.permissions = null;
    },
  },
});

export const {
  login,
  logout,
  changePassword,
  checking,
  errorLogin,
  errorLogout,
  errorChangePassword,
  recoveryPassword,
  errorRecoveryPassword,
  resetPassword,
  errorResetPassword,
  setLoading,
} = authSlice.actions;
