/* eslint-disable camelcase */
import { useState } from 'react';
import {
  numberValidator,
  selectValidator,
  textValidator,
} from '../../validation/validators/validators';

const touchErrors = (errors) =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {
  });

const usePaymentsValidator = (formState) => {
  const [errors, setErrors] = useState({
    paymentDate: {
      dirty: false,
      error: false,
      message: '',
    },
    paymentAmount: {
      dirty: false,
      error: false,
      message: '',
    },
    currencyId: {
      dirty: false,
      error: false,
      message: '',
    },
    paymentTermId: {
      dirty: false,
      error: false,
      message: '',
    },
  });

  // eslint-disable-next-line no-shadow
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    // eslint-disable-next-line camelcase
    const { paymentDate, paymentAmount, currencyId, paymentTermId } = form;

    if (
      nextErrors.paymentDate.dirty &&
      (field ? field === 'paymentDate' : true)
    ) {
      const paymentDateMessage = textValidator(paymentDate, form);
      nextErrors.paymentDate.error = !!paymentDateMessage;
      nextErrors.paymentDate.message = paymentDateMessage;
      if (paymentDateMessage) isValid = false;
    }

    if (
      nextErrors.paymentAmount.dirty &&
      (field ? field === 'paymentAmount' : true)
    ) {
      const paymentAmountMessage = numberValidator(paymentAmount, form);
      nextErrors.paymentAmount.error = !!paymentAmountMessage;
      nextErrors.paymentAmount.message = paymentAmountMessage;
      if (paymentAmountMessage) isValid = false;
    }

    if (
      nextErrors.currencyId.dirty &&
      (field ? field === 'currencyId' : true)
    ) {
      const currencyIdMessage = selectValidator(currencyId, form);
      nextErrors.currencyId.error = !!currencyIdMessage;
      nextErrors.currencyId.message = currencyIdMessage;
      if (currencyIdMessage) isValid = false;
    }

    if (
      nextErrors.paymentTermId.dirty &&
      (field ? field === 'paymentTermId' : true)
    ) {
      const paymentTermIdMessage = selectValidator(paymentTermId, form);
      nextErrors.paymentTermId.error = !!paymentTermIdMessage;
      nextErrors.paymentTermId.message = paymentTermIdMessage;
      if (paymentTermIdMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };
    validateForm({
      form: formState, field, errors: updatedErrors
    });
  };

  return {
    ...formState,
    formState,
    errors,
    validateForm,
    onBlurField,
  };
};

export default usePaymentsValidator;
