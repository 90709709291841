import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../config/basequery';
import configMultipart from '../config/basequeryMultipart';

// eslint-disable-next-line import/no-cycle
import {
  addOrder,
  changeLoading,
  deleteOrder,
  editOrder,
  onLoadingOrder,
  selectClients,
  selectItems,
  selectOrder,
  selectOrderStatuses,
  selectOrdersClient,
  selectSectors,
  setLastPage,
} from './orderSlice';

const getOrders = createAsyncThunk('order/getOrders', async (_, { getState, dispatch }) => {
  try {
    const state = getState();
    const { currentPageOrder, orderColumn, searchValue } = state.order;
    const params = { 
      page: currentPageOrder+1,
      per_page: 10,
      column_name: orderColumn.column || 'id',
      column_order: orderColumn.order || 'desc',
      search: searchValue
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/orders`, {
        params,
        ...config()
      }
    );
    dispatch(setLastPage(data.orders.last_page));
    return data?.orders?.data;
  } catch (error) {
    toast(error);
    return [];
  }
});

export default getOrders;

export const createOrder = (form) => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/orders`,
      form,
      configMultipart()
    );
    await dispatch(addOrder(data));
    toast.success(data.message);
    return;
  } catch (error) {
    const data = error.response.data.message;
    toast.error(data);
    // toast.error(data.message)
    // toast.error(error.message.original.message)
    // if (data) {
    //  Object.keys(data).forEach((key) => toast.error(data[key]))
    // } else {
    // toast.error('Error creating order')
    // }
  }
};

export const editsOrder =
  ({ id, _body, isOpen }) =>
    async (dispatch) => {
      dispatch(onLoadingOrder());
      if (isOpen) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_API_BACKEND}api/orders/${id}`,
            _body,
            configMultipart()
          );
          dispatch(editOrder(data));
          toast.success(data.message);
          return;
        } catch (error) {
          const data = error.response.data.message;
          toast.error(data);
        }
      } else {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_API_BACKEND}api/orders`,
            _body,
            configMultipart()
          );
          dispatch(editOrder(data));
          toast.success(data.message);
          return;
        } catch (error) {
          const data = error.response.data.message;
          toast.error(data);
        }
      }
    };

export const deletesOrder = (id) => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BACKEND}api/orders/${id}`,
      config()
    );
    dispatch(deleteOrder(id));
    toast.success(data.message, {
      duration: 3000
    });
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getClients = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const query = '?column_name=id&column_order=desc';
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/clients${query}`,
      config()
    );
    dispatch(selectClients(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getSectors = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/sectors`,
      config()
    );
    dispatch(selectSectors(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export const getItems = () => async (dispatch) => {
  dispatch(onLoadingOrder());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/item`,
      config()
    );
    dispatch(selectItems(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export async function closeOrder(id) {
  try {
    const data = await axios.put(
      `${process.env.REACT_APP_API_BACKEND}api/orders/close/${id}`,
      config()
    );
    toast.dismiss();
    toast.success(data.data.message);
    return {
      ...data.data.order,
      payment_status: {
        payment_status: 'Pago Pendiente',
        id: 2,
      },
    };
  } catch (data) {
    toast.error(data.response.data.message);
    return [];
  }
}

export const markCommOrder = (order) => async (dispatch) => {
  try {
    const data = await axios.put(
      `${process.env.REACT_APP_API_BACKEND}api/orders/markComm/${order.id}`,
      config()
    );
    toast.dismiss();
    toast.success(data.data.message);
    dispatch(selectOrder(data.data.order));
    return {
      ...data.data.order,
      commission_paid: true,
    };
  } catch (data) {
    toast.dismiss();
    toast.error(data.response.data.message);
    return [];
  }
};

export const getOrderStatuses = () => async (dispatch, getState) => {
  dispatch(changeLoading(true));

  const { orderStatuses } = getState().order;

  if (orderStatuses.length > 0) {
    dispatch(selectOrderStatuses(orderStatuses));
    dispatch(changeLoading(false));
    return;
  }
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/order_statuses`,
      config()
    );
    dispatch(selectOrderStatuses(data?.order_statuses));
    dispatch(changeLoading(false));
    return;
  } catch (data) {
    toast.error(data.message);
  }
};

export async function editStatusOrder(orderState, orderId) {
  try {
    const data = await axios.put(
      `${process.env.REACT_APP_API_BACKEND}api/orders/updateStatusOrder/${orderId}`,
      orderState
    );
    toast.dismiss();
    toast.success(data.data.message);
    return data.data.order;
  } catch (data) {
    toast.error(data.response.data.message);
    return [];
  }
}

export const getOrdersbyClient = (id) => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}api/orders/client/${id}`,
      config()
    );
    dispatch(selectOrdersClient(data));
    return;
  } catch (data) {
    toast.error(data.message);
  }
  finally{
    dispatch(changeLoading(false));
  }
};

export const duplicateOrder = (id) => async (dispatch) => {
  // dispatch(onLoadingOrder())
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}api/orders/duplicate/${id}`,
      config()
    );
    dispatch(getOrders());
    toast.success(data.message);
    return;
  } catch (data) {
    toast.error(data.message);
  }
};
