import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import getPaymentTerms from './paymentTerms';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    payment: [],
    paymentTerms: [],
    loading: false,
  },

  reducers: {
    addPayment: (state, { payload }) => {
      state.payment = [...state.payment, {
        ...payload
      }];
    },

    selectClient: (state, { payload }) => {
      state.editClients = state.client.find(
        (client) => client.id === payload.id
      );
    },

    onLoadingPayment: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentTerms.fulfilled, (state, { payload }) => {
      state.paymentTerms = payload;
      state.loading = false;
    });
    builder.addCase(getPaymentTerms.rejected, (state) => {
      state.paymentTerms = 'fail';
    });
  },
});

export const { addPayment, selectClient, onLoadingPayment } =
  paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
