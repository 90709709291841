export const CURRENCIES = {
  peso: '1',
  dollar: '2',
};

export const FACILITY_MATERIAL = 'MMA';

export const IS_QUOTE_REQUEST = 0;
export const QUOTE_REQUEST_CONFIRMATION = 1;

export const MATERIAL_TYPE = {
  PRODUCT: 1,
  MATERIAL: 2,
  LABOR: 3
};
export const UNIT_TYPE = {
  UNITARY: 1,
  LONGITUDE: 2,
  SURFACE: 3,
  VOLUME: 4,
  DISTANCE: 5,
  TIME: 6
};

