import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../../presentation/components/Layouts/Layout';
import pagesData from '../../presentation/pages/pagesData';

function Router() {
  const pageRoutes = pagesData.map(({ path, title, element }) => (
    <Route key={ title } path={ `/${path}` } element={ element } />
  ));
  return (
    <Routes>
      <Route path="/" element={ <Layout /> }>
        {pageRoutes}
      </Route>
    </Routes>
  );
}

export default Router;
