import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config/basequery';

const getPaymentTerms = createAsyncThunk(
  'payment/getPayment_terms',
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BACKEND}api/payment_terms`,
        config()
      );
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export default getPaymentTerms;
