import React, {
  useEffect, useState, lazy, Suspense
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ordersOptions } from '../../../helpers/dataOptions';
import NavSec from '../NavSec/NavSec';
import getOrders from '../../../data/redux/Orders/thunks';
import Spinner from '../Spinner/Spinner';
import getOrdersStatuses from '../../../data/redux/OrderStatuses/thunks';
import QuotesDesktop from './components/QuotesDesktop';
import QuotesMobile from './components/QuotesMobile';
import { getQuotes } from '../../../data/redux/Quotes/getQuotes';
import {
  setCurrentPageOrder, setOrderColumn, setSearchValue 
} from '../../../data/redux/Orders/orderSlice';

const OrdersDesktop = lazy(() => import('./components/OrdersDesktop'));
const OrdersMobile = lazy(() => import('./components/OrdersMobile'));

function Orders() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentPageOrder, orderColumn } = useSelector((state) => state.order);
  const { ordersStatuses } = useSelector((state) => state.orderStatuses);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 860);
  const [isQuoteMode, setIsQuoteMode] = useState(false);

  useEffect(() => {
    const getQuoteMode = () => {
      const query = new URLSearchParams(location.search);
  
      const isQuote = query.get('view') === 'quotes';
      const includeQuote = location.pathname.includes('quotes');
  
      return isQuote || includeQuote;
    };

    const quoteMode = getQuoteMode();
    setIsQuoteMode(quoteMode);
  }, [location.search, location.pathname]);

  useEffect(() => {
    const desktopMediaQuery = window.matchMedia('(min-width: 860px)');

    const updateIsMobile = () => {
      setIsMobile(!desktopMediaQuery.matches);
    };

    updateIsMobile();

    desktopMediaQuery.addEventListener('change', updateIsMobile);

    return () => {
      desktopMediaQuery.removeEventListener('change', updateIsMobile);
    };
  }, []);

  useEffect(() => {
    dispatch(setCurrentPageOrder(0)); 
    dispatch(
      setOrderColumn({
        column: 'id',
        order: 'desc',
        id: 0,
      })); 
    dispatch(setSearchValue('')); 
  }, [isQuoteMode]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          isQuoteMode ? dispatch(getQuotes()) : dispatch(getOrders()),
          ordersStatuses?.length === 0 && dispatch(getOrdersStatuses()),
        ]);
      } catch (error) {
        toast.error('Hubo un error al cargar los datos. Por favor, inténtelo de nuevo.');
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [isQuoteMode, currentPageOrder, orderColumn]);

  const selectComponent = () => {
    if (!isQuoteMode) {
      return !isMobile ? <OrdersDesktop /> : <OrdersMobile />;
    }
    return !isMobile ? <QuotesDesktop /> : <QuotesMobile />;
  };

  return (
    <div className="container-main-orders-gral">
      <NavSec title="PEDIDOS" content={ ordersOptions } />
      <Suspense fallback={ <Spinner /> }>
        { isLoading ? <Spinner /> :
          <>
            <div className='quotes'>
              <button type='button'
                className={ `qts-btn ${!isQuoteMode ? 'qts-act' : ''}` }
                onClick={ () => {
                  setIsQuoteMode(false);
                  window.history.pushState({
                  }, '', '/orders?view=orders');
                } }
              >PEDIDOS</button>
              <button type='button'
                className={ `qts-btn ${isQuoteMode ? 'qts-act' : ''}` }
                onClick={ () => {
                  setIsQuoteMode(true);
                  window.history.pushState({
                  }, '', '/orders?view=quotes');
                } }>COTIZACIONES</button>
            </div>
            {selectComponent()}
          </>}
      </Suspense>
    </div>
  );
}

export default Orders;
