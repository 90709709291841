import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';

function CustomPagination({ currentPage, handlePagination, pageCount }) {

  return (
    <ReactPaginate
      previousLabel=""
      nextLabel=""
      forcePage={ currentPage }
      onPageChange={ (page) => handlePagination(page) }
      pageCount={ pageCount }
      breakLabel="..."
      pageRangeDisplayed={ 2 }
      marginPagesDisplayed={ 2 }
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      nextLinkClassName="page-link"
      nextClassName="page-item next"
      previousClassName="page-item prev"
      previousLinkClassName="page-link"
      pageLinkClassName="page-link"
      containerClassName="pagination"
    />
  );
}

CustomPagination.propTypes = {
  currentPage: PropTypes.number,
  handlePagination: PropTypes.func,
  pageCount: PropTypes.number,
};

CustomPagination.defaultProps = {
  currentPage: 0,
  handlePagination: () => { },
  pageCount: 1,
};

export default CustomPagination;
