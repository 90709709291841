import React from 'react';
import PropTypes from 'prop-types';

function DetailsTablePayment({ data }) {
  return (
    <div className="container-units">
      <div className="container-detail-units">
        <h3 className="h-detail-units">TOTAL A PAGAR:</h3>
        <p className="units-d">
          $ {data.peso_total - data.peso_bonification} - US${' '}
          {data.dollar_total - data.dollar_bonification}
        </p>
        <h3 className="h-detail-units">COMISION:</h3>
        <p className="units-d">
          $ {data?.peso_commission} - US$ {data?.dollar_commission}
        </p>
      </div>
      <div className="line-red">.</div>
    </div>
  );
}

DetailsTablePayment.propTypes = {
  data: PropTypes.shape({
    peso_total: PropTypes.number,
    peso_bonification: PropTypes.number,
    peso_commission: PropTypes.number,
    dollar_total: PropTypes.number,
    dollar_bonification: PropTypes.number,
    dollar_commission: PropTypes.number,
  }).isRequired,
};

export default DetailsTablePayment;
