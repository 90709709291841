import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import Table from '../../Table/Table';
import columnsTableDesktop from '../Tables/columnsTableDesktop';
import DetailsTableDesktop from './DetailsTableDesktop';

const conditionalStyles = [
  {
    when: (row) => !row.enabled,
    style: {
      color: '#c5b6b6',
    },
  },
];

function ClientDesktop() {
  const { client } = useSelector((state) => state.client);
  const [orderedClient, setOrderedClient] = useState([]);
  const { permissions } = useSelector((state) => state.auth);

  useEffect(() => {
    if (client) {
      setOrderedClient(client);
    }
  }, [client]);

  return (
    <Table
      searchByDb={{
        endpoint: '/clients',
        params: {
        },
        isUsed: true,
      }}
      className={
        permissions.includes('client-update')
          ? 'marmoleria--datatable datatable-client'
          : 'marmoleria--datatable datatable-client-sin'
      }
      selectRows={ false }
      columnsTable={
        permissions.includes('client-update')
          ? columnsTableDesktop
          : columnsTableDesktop.slice(0, -1)
      }
      filteredData={ orderedClient }
      detailsTable={ DetailsTableDesktop }
      filterEnabled
      conceptsFilter={ [
        'fullname',
        'email',
        'cuit',
        'company',
        'company',
        'address',
        'phone',
        'observations',
        'client_type.client_type',
      ] }
      conditionalStyles={ conditionalStyles }
      section="client"
      messageNoData={
        <h1 className="no-data-message">NO SE REGISTRARON CLIENTES</h1>
      }
      sortServer
    />
  );
}

export default ClientDesktop;
