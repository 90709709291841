import ButtonUnitsMod from '../components/ButtonUnitsMod';
import { SORT_ORDER } from '../../User/Tables/columnsTableDesktop';

export const nomenclatureCaseInsensitiveSort = (rowA, rowB) => {
  const nomenclatureA = rowA.nomenclature.toLowerCase();
  const nomenclatureB = rowB.nomenclature.toLowerCase();

  if (nomenclatureA < nomenclatureB) return SORT_ORDER.BEFORE;
  if (nomenclatureA > nomenclatureB) return SORT_ORDER.AFTER;
  return SORT_ORDER.EQUAL;
};

export const measurementUnitCaseInsensitiveSort = (rowA, rowB) => {
  const measurementUnitA = rowA.measurement_unit.toLowerCase();
  const measurementUnitB = rowB.measurement_unit.toLowerCase();

  if (measurementUnitA < measurementUnitB) return SORT_ORDER.BEFORE;
  if (measurementUnitA > measurementUnitB) return SORT_ORDER.AFTER;
  return SORT_ORDER.EQUAL;
};

const columnsTableDesktop = [
  {
    name: 'UNIDAD',
    selector: (row) => row?.measurement_unit,
    sortable: true,
    minWidth: '30%',
    sortFunction: measurementUnitCaseInsensitiveSort 
  },
  {
    name: 'NOMENCLATURA',
    selector: (row) => row?.nomenclature,
    sortable: true,
    minWidth: '30%',
    sortFunction: nomenclatureCaseInsensitiveSort
  },
  {
    name: 'ENTERO / DECIMAL',
    selector: (row) => (row?.is_decimal ? 'DECIMAL' : 'ENTERO'),
    sortable: true,
    minWidth: '30%',
  },
  {
    name: '',
    cell: (row) => ButtonUnitsMod(row),
    sortable: true,
    button: true,
    minWidth: '5%',
  },
];

export default columnsTableDesktop;
